import { FlyToInterpolator } from "react-map-gl";
import { easeCircleInOut } from "d3-ease";
import _ from "lodash";
import mapboxgl from "mapbox-gl";
import getCoordsFromBounds from "./getCoordsFromBounds";

/**
 * Takes an array of clusters, returns a viewport based on the locations inside
 * Useful when you don't know the size of the search area.
 */
export default function createSearchViewport(clusters, viewport) {
  const bounds = new mapboxgl.LngLatBounds();

  clusters.forEach(function(cluster) {
    const types = _.get(cluster, "types");
    if (types) {
      Object.keys(types).forEach(function(type) {
        const locations = types[type];
        locations.forEach(function(location) {
          const lat = _.get(location, "coordinates.lat");
          const lng = _.get(location, "coordinates.lng");
          if (lat && lng) {
            bounds.extend([lng, lat]);
          }
        });
      });
    }
  });

  const { longitude, latitude, zoom } = getCoordsFromBounds({ viewport, bounds, padding: 160 });

  return {
    longitude: longitude,
    latitude: latitude,
    zoom: Math.min(zoom, 15),
    transitionDuration: 1700,
    transitionInterpolator: new FlyToInterpolator(),
    transitionEasing: easeCircleInOut,
    pitch: 0,
    bearing: 0
  };
}

import { createStore } from "redux";
import viewState from "../config/viewState";
import initialState from "./initialState";

import {
  SET_HIGHLIGHT,
  SET_CLUSTER,
  SET_FILTERS,
  SET_TILE,
  SET_STEP,
  SET_MARKER,
  SET_TILES,
  SET_VIEWPORT,
  SET_SEARCH,
  SET_LABELS,
  SET_MAP_DATA,
  SET_IS_TRANSITIONING,
  SET_DEFAULT_VIEWSTATE,
  RESET_MAP,
  SET_LOCATION,
  FILTER_TO_REGION,
  FILTER_TO_LOCATIONS
} from "./actions";

import getClusters from "../utils/getClusters";
import getTypes from "../utils/getTypes";

let defaultTypes = [];

function mapState(state = initialState, action) {
  if (action && action.type) {
    switch (action.type) {
      case SET_IS_TRANSITIONING:
        return Object.assign({}, state, {
          isTransitioning: action.payload
        });

      case SET_HIGHLIGHT:
        return Object.assign({}, state, {
          highlight: action.payload
        });

      case SET_FILTERS:
        return Object.assign({}, state, {
          filters: {
            ...state.filters,
            ...action.payload
          }
        });

      case SET_MARKER:
        return Object.assign({}, state, {
          selectedMarker: action.payload
        });

      case SET_STEP:
        return Object.assign({}, state, {
          step: action.payload
        });

      case SET_TILES:
        /**
         * Grab and store default types for resets
         */
        defaultTypes = getTypes(getClusters(action.payload));

        return Object.assign({}, state, {
          tiles: action.payload,
          filters: {
            ...state.filters,
            types: [...defaultTypes]
          }
        });

      case SET_VIEWPORT:
        return Object.assign({}, state, {
          viewport: action.payload
        });

      case SET_LABELS:
        return Object.assign({}, state, {
          labels: {
            ...state.labels,
            ...action.payload
          }
        });

      case SET_SEARCH:
        return Object.assign({}, state, {
          filters: {
            ...state.filters,
            ...action.payload.filters
          },
          step: action.payload.step
        });

      case SET_MAP_DATA:
        return Object.assign({}, state, {
          mapData: action.payload
        });

      case SET_DEFAULT_VIEWSTATE:
        return Object.assign({}, state, {
          defaultViewState: action.payload
        });

      case SET_TILE:
        return Object.assign({}, state, {
          selectedTile: action.payload.tile,
          step: viewState.clusters,
          selectedLocation: false,
          selectedCluster: false,
          filters: {
            ...state.filters,
            ...action.payload.filters
          },
          mapData: {
            ...state.viewport,
            ...action.payload.viewport
          }
        });

      case SET_CLUSTER:
        return Object.assign({}, state, {
          selectedCluster: action.payload.cluster,
          selectedTile: action.payload.tileId ? action.payload.tileId : state.selectedTile,
          step: viewState.locations,
          selectedLocation: false,
          mapData: {
            ...state.viewport,
            ...action.payload.viewport
          }
        });

      case SET_LOCATION:
        if (action.payload) {
          return Object.assign({}, state, {
            selectedLocation: action.payload.location,
            selectedTile: action.payload.location.tileId,
            selectedCluster: action.payload.clusterId
              ? action.payload.clusterId
              : state.selectedCluster,
            step: viewState.locations,
            filters: {
              ...state.filters,
              ...action.payload.filters
            },
            mapData: {
              ...state.viewport,
              ...action.payload.viewport
            }
          });
        }
        return Object.assign({}, state, {
          selectedLocation: false
        });

      case FILTER_TO_REGION:
        return Object.assign({}, state, {
          step: viewState.locations,
          selectedTile: action.payload.tile,
          filters: {
            ...state.filters,
            ...action.payload.filters
          },
          mapData: {
            ...state.viewport,
            ...action.payload.viewport
          }
        });

      case FILTER_TO_LOCATIONS:
        return Object.assign({}, state, {
          step: viewState.locations,
          selectedLocation: action.payload.location,
          filters: {
            ...state.filters,
            ...action.payload.filters
          },
          mapData: {
            ...state.viewport,
            ...action.payload.viewport
          }
        });

      case RESET_MAP:
        return Object.assign({}, state, {
          highlight: null,
          selectedTile: null,
          selectedCluster: null,
          selectedMarker: null,
          selectedLocation: false,
          step: state.defaultViewState,
          filters: {
            types: [...defaultTypes],
            term: "",
            searchFilter: "",
            brand: ""
          },
          mapData: {
            ...state.viewport,
            ...action.payload
          }
        });

      default:
        return state;
    }
  } else {
    return state;
  }
}

export default createStore(
  mapState,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

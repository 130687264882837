import _ from "lodash";

/**
 * Get a tile by id
 */
export default function getTile(tiles, id) {
  if (tiles && id) {
    return _.find(tiles, { id });
  }
}

import React from "react";

import * as styles from "./styles";

function Line({ tile, position }) {
  const circle = { width: 10, height: 10 };

  const circleX = position.x;
  const circleY = position.y;

  let tile_x = tile.x,
    tile_y = tile.y,
    x = null,
    y = null,
    width = null,
    height = null,
    borderTop = null,
    borderBottom = null,
    borderRight = null,
    borderLeft = null;

  if (circleX > tile_x) {
    x =
      circleX - tile_x - tile.size.width / 2 + circle.width / 2 < 0
        ? circleX + circle.width / 2
        : tile_x + tile.size.width / 2;
    width = circleX - tile_x - tile.size.width / 2 + circle.width / 2;
    borderLeft = 1;
    borderRight = 0;
  } else {
    x = circleX + circle.width / 2;
    width = tile.x - circleX + tile.size.width / 2;
    borderLeft = 0;
    borderRight = 1;
  }

  if (circleY > tile_y) {
    y = tile.y + tile.size.height / 2;
    height = circleY - tile_y - tile.size.height / 2 + circle.width / 2;
    borderBottom =
      tile_x < circleX && circleX < tile_x + tile.size.width / 2 ? 0 : 1;
    borderBottom =
      tile_x + tile.size.width <= circleX &&
      tile_x + tile.size.width / 2 >= circleX
        ? 1
        : borderBottom;
    borderTop = 0;
  } else {
    y = circleY + circle.width / 2;
    height = tile_y - circleY + circle.width / 2;
    borderBottom = 0;
    borderTop =
      tile_x < circleX && circleX < tile_x + tile.size.width / 2 ? 0 : 1;
    borderTop =
      tile_x + tile.size.width <= circleX &&
      tile_x + tile.size.width / 2 >= circleX
        ? 1
        : borderTop;
  }

  const newPosition = { x, y };
  const size = { width, height };
  const border = { borderTop, borderBottom, borderLeft, borderRight };

  return <styles.Line position={newPosition} size={size} border={border} />;
}

export default React.memo(Line);

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import FilterCheckBox from "./FilterCheckBox";
import { RestaurantIcon, BarIcon, HotelIcon } from "../../../utils/icons";
import { setFilters, setLocation } from "../../../redux/actions";
import _ from "lodash";

import getTile from "../../../utils/getTile";
import getCluster from "../../../utils/getCluster";
import getTypes from "../../../utils/getTypes";

function TypeOptions() {
  const tiles = useSelector(state => state.tiles);
  const selectedTile = getTile(
    tiles,
    useSelector(state => state.selectedTile)
  );
  const selectedCluster = getCluster(
    selectedTile,
    useSelector(state => state.selectedCluster)
  );

  const selectedLocation = useSelector(state => state.selectedLocation);
  const labels = useSelector(state => state.labels);
  const filters = useSelector(state => state.filters);
  const types = filters.types;

  const dispatch = useDispatch();

  const icons = {
    hotel: <HotelIcon />,
    nightclub: <BarIcon />,
    restaurant: <RestaurantIcon />
  };

  const clusters = selectedCluster ? selectedCluster : _.get(selectedTile, "clusters", []);
  const availableTypes = getTypes(clusters).sort();

  const handleFilterChanged = (type, e) => {
    e.stopPropagation();
    const newTypes = [...types];
    const index = newTypes.indexOf(type);
    if (index === -1) {
      newTypes.push(type);
    } else {
      newTypes.splice(index, 1);
    }
    if (selectedLocation && newTypes.indexOf(selectedLocation.type) === -1) {
      dispatch(setLocation(null));
    }
    dispatch(
      setFilters({
        types: newTypes
      })
    );
  };

  return (
    <div>
      {availableTypes.map(type => (
        <FilterCheckBox
          onChange={e => handleFilterChanged(type, e)}
          key={type}
          name={type}
          id={type}
          checked={types.includes(type)}
          icon={icons[type]}
          label={labels[type]}
        ></FilterCheckBox>
      ))}
    </div>
  );
}

export default TypeOptions;

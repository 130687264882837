import getColorPallet from "../utils/getColorPallet";

export const size = {
  mobileXS: 275,
  mobileS: 320,
  mobileM: 375,
  mobileL: 425,
  tabletS: 600,
  tablet: 768,
  laptopS: 926,
  laptopM: 1284,
  laptopL: 1440,
  desktop: 1920
};

export default {
  //Media Queries
  devices: {
    mobileXS: `only screen and (min-width: ${size.mobileXS}px)`,
    mobileS: `only screen and (min-width: ${size.mobileS}px)`,
    mobileM: `only screen and (min-width: ${size.mobileM}px)`,
    mobileL: `only screen and (min-width: ${size.mobileL}px)`,
    tabletS: `only screen and (min-width: ${size.tabletS}px)`,
    tablet: `only screen and (min-width: ${size.tablet}px)`,
    laptopS: `only screen and (min-width: ${size.laptopS}px)`,
    laptopM: `only screen and (min-width: ${size.laptopM}px)`,
    laptopL: `only screen and (min-width: ${size.laptopL}px)`,
    desktop: `only screen and (min-width: ${size.desktop}px)`,
    desktopL: `only screen and (min-width: ${size.desktop}px)`
  },
  // colors
  colors: getColorPallet(JSON.parse(localStorage.getItem("queryGeoTilesConfig"))),
  // fonts
  fonts: {
    base: "400 18px/18px HKGrotesk sans-serif",
    sansSerifStack: "HKGrotesk"
  },
  appMaxWidth: "1920px"
};

import {
  sanderson,
  shoreClub,
  southBeachTheme,
  mondrianDolla,
  theRedbury,
  hudson,
  hydeMidtown,
  defaultTheme,
  delano,
  stMartinsLane,
  karakoy,
  umami,
  filia,
  cleo,
  bazzarMar,
  katsuya,
  hyde
} from "../themes";

import _ from "lodash";

//Drupal Names
/* 
  sanderson ok
  shoreClub ok
  sls ok
  mondrian ok
  redbury ok
  hudson ok 
  hydeMidtown ok
  sbe ok
  delano ok
  stMartinsLane ok 
  karakoy ok
  umamiBurger ok
  filia ok
  cleo ok
  bazzarMar ok
  katsuya ok
  hydeSunset ok
  carna N/A
  skybar N/A
  leynia N/A
  sBar N/A
*/
export default function getColorPallet(options) {
  const themes = {
    sls: southBeachTheme,
    mondrian: mondrianDolla,
    redbury: theRedbury,
    hydeMidtown: hydeMidtown,
    sanderson: sanderson,
    shoreClub: shoreClub,
    delano: delano,
    hudson: hudson,
    stMartinsLane: stMartinsLane,
    karakoy: karakoy,
    umamiBurger: umami,
    filia: filia,
    cleo: cleo,
    bazzarMar: bazzarMar,
    katsuya: katsuya,
    hydeSunset: hyde
  };
  const theme = _.get(options, "theme");
  let themeData = defaultTheme;

  if (themes[theme]) {
    themeData = themes[theme];
  }

  return Object.assign({}, themeData, _.get(options, "themeOverrides", {}));
}

export default function getSearchFilterOptions(tiles) {
  if (tiles) {
    const types = [];
    const brands = Object.values(tiles).reduce((obj, tile) => {
      Object.values(tile.clusters).forEach(cluster => {
        Object.entries(cluster.types).forEach(data => {
          const type = data[0];
          if (!types.includes(type.toLowerCase())) {
            types.push(type.toLowerCase());
          }
          cluster.types[type].forEach(location => {
            if (obj[type]) {
              obj[type] = !obj[type].includes(location.brand)
                ? [...obj[type], location.brand]
                : obj[type];
            } else {
              obj[type] = [location.brand];
            }
          });
        });
      });
      return obj;
    }, {});

    return {
      types,
      brands
    };
  }

  return false;
}

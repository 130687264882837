import _ from "lodash";

/**
 * Get all the locations in the given clusters
 */
export default function getLocations(clusters) {
  const locations = [];

  if (clusters && !Array.isArray(clusters)) {
    clusters = [clusters];
  }

  if (clusters && clusters.length > 0) {
    clusters.forEach(function(cluster) {
      Object.keys(_.get(cluster, "types", {})).forEach(function(type) {
        cluster.types[type].forEach(function(location) {
          locations.push({
            ...location
          });
        });
      });
    });
  }
  return locations;
}

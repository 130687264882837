import React from "react";

import * as styles from "./styles";

function Circle({ position }) {
  const size = { width: 10, height: 10 };
  return <styles.Circle position={position} size={size} />;
}

export default React.memo(Circle);

import React, { useState, useEffect, createRef, useCallback } from "react";
import ReactMapGL, { FlyToInterpolator, NavigationControl } from "react-map-gl";
import { easeExpInOut } from "d3-ease";
import Map from "../../components/Map/Map";
import Spinner from "../Spinner/Spinner";
import axios from "axios/index";
import {
  setTiles,
  setCluster,
  setViewport,
  setMapData,
  setIsTransitioning,
  setLocation,
  setTile,
  resetMap,
  setLabels
} from "../../redux/actions";
import { dataLayer } from "../../utils/mapStyles";
import { fromJS } from "immutable";
import hightlightMap from "../../utils/highlightMap";
import Search from "../../components/Search/Search";
import Navigation from "../../components/Navigation/Navigation";
import Filters from "../../components/Filters/Filters";
import InfoSlide from "../../components/InfoSlide/InfoSlide";
import { size } from "../../themes/index.variables";
import { useDispatch, useSelector } from "react-redux";
import getColorPallet from "../../utils/getColorPallet";
import getClusterByName from "../../utils/getClusterByName";
import getLocationByName from "../../utils/getLocationByName";
import getLocations from "../../utils/getLocations";
import createClusterViewport from "../../utils/createClusterViewport";
import createLocationViewport from "../../utils/createLocationViewport";
import createRegionViewport from "../../utils/createRegionViewport";
import createBaseViewport from "../../utils/createBaseViewport";
import _ from "lodash";
import viewState from "../../config/viewState";

const MapContainer = ({ cssLoading }) => {
  const mapRef = createRef();

  const dispatch = useDispatch();

  // Extract data from the Redux store with useSelector
  const mapData = useSelector(state => state.mapData);
  const tiles = useSelector(state => state.tiles);
  const step = useSelector(state => state.step);
  const [mapStyle, setMapStyle] = useState(null);
  const [isDraggable, setIsDraggable] = useState(false);
  const viewport = useSelector(state => state.viewport);
  const [initialMapStyle, setInitialMapStyle] = useState(null);
  const labels = useSelector(state => state.labels);
  const config = useSelector(state => state.config);

  const [loading, setLoading] = useState(true);

  //Get theme based on the option set on localStorage
  const theme = getColorPallet(
    localStorage.getItem("queryGeoTilesConfig")
      ? JSON.parse(localStorage.getItem("queryGeoTilesConfig"))
      : null
  );

  const mapTheme = theme.mapTheme;
  const [showingSpinner, setShowSpinner] = useState(true);

  //Spinner fade-out Animation
  useEffect(() => {
    if (!loading && !cssLoading) {
      setTimeout(() => {
        setShowSpinner(loading);
      }, 1000);
    }
  }, [loading, setShowSpinner, cssLoading]);

  //Reset viewport if width or height or zoom change on the store
  useEffect(() => {
    dispatch(
      setViewport({
        width: mapData.width,
        height: mapData.height,
        zoom: mapData.zoom
      })
    );
    // eslint-disable-next-line
  }, [mapData.width, mapData.height, mapData.mapZoom]);

  //Set clusters and locations, this should run once
  useEffect(() => {
    let cluster = null;
    let location = null;

    //Zoom to set location if a location is set in localhost
    if (tiles && !loading) {
      if (_.get(config, "location")) {
        location = getLocationByName(tiles, _.get(config, "location.name"));
        if (location) {
          dispatch(
            setLocation({
              location,
              clusterId: location.clusterId,
              viewport: {
                ...createLocationViewport(location),
                transitionDuration: 700
              }
            })
          );
        }
      } else if (_.get(config, "cluster")) {
        cluster = getClusterByName(tiles, _.get(config, "cluster"));
        if (cluster) {
          dispatch(
            setCluster({
              cluster: cluster.id,
              tileId: cluster.tileId,
              viewport: createClusterViewport(cluster, viewport)
            })
          );
        }
      } else if (_.get(config, "region")) {
        const region = Object.values(tiles).find(region => {
          return _.get(region, "name", "").toLowerCase() === config.region.toLowerCase();
        });
        if (region) {
          dispatch(
            setTile({
              region,
              viewport: createRegionViewport(region, viewport)
            })
          );
        }
      }
    }

    // eslint-disable-next-line
  }, [tiles, config, loading]);

  // Create mapbox sources for the continent areas
  // For more info, check https://docs.mapbox.com/help/glossary/source/
  useEffect(() => {
    const map = _.result(mapRef, "current.getMap");

    //Create sources for map layers
    map &&
      map.on("load", () => {
        dispatch(resetMap(createBaseViewport()));

        const regionsHighlight = [];
        if (tiles && tiles.length > 0) {
          //Create an array of highlight areas to create mapbox sources
          tiles.forEach(function(tile) {
            _.get(tile, "highlight", []).forEach(function(highlight) {
              const area = highlight.toUpperCase();
              if (!regionsHighlight.includes(area)) {
                regionsHighlight.push(area);
              }
            });
          });
        }

        if (regionsHighlight.length > 0) {
          const newList = regionsHighlight.reduce((list, area) => {
            const newMuttable = list.setIn(
              ["sources", `highlight${area}`],
              fromJS({ type: "geojson", data: hightlightMap([area]) })
            );
            return newMuttable;
          }, fromJS(map.style.stylesheet));
          setMapStyle(newList);
          setInitialMapStyle(newList);
        }
      });
  }, [tiles, mapRef, dispatch]);

  useEffect(() => {
    //Remove highlight after each step change
    removeHighlight();

    //If step 1, set the map to draggable
    if (step === viewState.clusters || step === viewState.locations || step === viewState.search) {
      setIsDraggable(true);
    } else {
      setIsDraggable(false);
    }
    // eslint-disable-next-line
  }, [step]);

  // Helper function to highlight the continents/areas
  const highlightArea = highlight => {
    const map = _.result(mapRef, "current.getMap");
    const currentMapStyle = fromJS(map.style.stylesheet);

    if (highlight && config.hasCountriesHover) {
      const newMap = highlight.reduce((list, area) => {
        if (!map.getSource(`highlight${area.toUpperCase()}`)) {
          return initialMapStyle;
        }
        let newMapStyle = list;

        if (!map.getLayer(`${area.toUpperCase()}Layer`)) {
          newMapStyle = list.set("layers", list.get("layers").push(dataLayer(area.toUpperCase())));
        }

        return newMapStyle;
      }, currentMapStyle);

      setMapStyle(newMap);
    } else {
      setMapStyle(initialMapStyle);
    }
  };

  const removeHighlight = () => {
    setMapStyle(initialMapStyle);
  };

  const onChangeViewport = mapData => {
    //if viewport zoom too small, no pitch or bearing (flat map instead)
    if (mapData.zoom <= 2) {
      mapData.pitch = 0;
      mapData.bearing = 0;
    }

    dispatch(setMapData(mapData));
  };

  const onTransitionStart = () => {
    dispatch(setIsTransitioning(true));
  };

  const onTransitionEnd = () => {
    dispatch(setIsTransitioning(false));
  };

  //Remove Spinner on idle map
  const handleOnLoad = () => {
    const map = mapRef && mapRef.current && mapRef.current.getMap();
    if (map) {
      map &&
        map.on("idle", () => {
          setLoading(false);
        });
    }
  };

  // Request to IPS
  const getData = useCallback(async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_FLOW_ENDPOINT_URL + "/content/feeds/mapData?clientId=sbe", {
        headers: {
          Accept: "application/json"
        }
      });
      const mapData = _.get(response, 'data')

      if (typeof mapData.data === "object" && !mapData.data.length) {
        mapData.data = [mapData.data];
      }

      let regions = _.get(mapData, "data", {});
      if (regions) {
        /**
         * Clean the data
         */
        Object.keys(regions).forEach(function(tileId) {
          const tile = regions[tileId];
          const clusters = _.get(tile, "clusters", {});
          tile.id = tileId;
          tile.coordinates = parseCoordinates(tile);

          Object.keys(clusters).forEach(function(clusterId) {
            const cluster = clusters[clusterId];
            cluster.id = clusterId;
            cluster.tileId = tile.id;
            cluster.coordinates = parseCoordinates(cluster);
            Object.keys(_.get(cluster, "types", {})).forEach(function(type) {
              const locations = cluster.types[type];
              locations.forEach(function(location) {
                location.tileId = tile.id;
                location.clusterId = cluster.id;
                location.type = type;
                location.coordinates = parseCoordinates(location);
              });
            });
          });
          tile.clusters = Object.values(clusters);
        });

        regions = Object.values(regions);

        /**
         * Apply config filters
         */

        const locationFilter = _.get(config, "location", {});

        let isolatedLocationId;
        if (locationFilter && locationFilter.isolated) {
          const location = getLocationByName(regions, locationFilter.name);
          if (location) {
            isolatedLocationId = location.id;
          }
        }

        const filterBrands = _.get(config, "filterBrands", []);
        const filterTypes = _.get(config, "filterTypes", []);

        regions.forEach(function(tile) {
          tile.clusters.forEach(function(cluster) {
            Object.keys(_.get(cluster, "types", {})).forEach(function(type) {
              cluster.types[type] = cluster.types[type].filter(location => {
                // Isolated location check
                if (isolatedLocationId) {
                  return isolatedLocationId === location.id;
                }

                if (
                  filterBrands.length > 0 &&
                  filterBrands.indexOf(location.brand.toLowerCase()) === -1
                ) {
                  return false;
                }

                if (filterTypes.length > 0 && filterTypes.indexOf(location.type) === -1) {
                  return false;
                }

                return true;
              });
            });
          });
        });

        /**
         * Clean out empty clusters
         */
        regions.forEach(function(tile) {
          tile.clusters = tile.clusters.filter(cluster => {
            return getLocations(cluster).length > 0;
          });
        });

        regions.sort((a, b) => b.coordinates.lng + a.coordinates.lng); //Set Lng first
        regions.sort((a, b) => a.coordinates.lat - b.coordinates.lat); //then Lat for correct overlay in the map

        dispatch(setTiles(regions));
      }
    } catch (error) {}
  }, [dispatch, config]);

  const loadConfig = useCallback(async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_FLOW_ENDPOINT_URL + "/config?clientId=sbe", {
        headers: {
          Accept: "application/json"
        },
        params: {
          primaryLangId: config.language,
          providerType: "map"
        }
      });

      const data = _.get(response, "data", []);
      const newLabels = {};
      if (data.length > 0) {
        data.forEach(function(translatedLabels) {
          Object.assign(newLabels, translatedLabels);
        });
      }
      // replace with set labels
      newLabels.nightlifes = labels.nightlifeVenues; // support for type + "s" used in some components
      dispatch(setLabels(newLabels));
    } catch (error) {}
  }, [config.language, dispatch, labels.nightlifeVenues]);

  useEffect(() => {
    //Get data from IPS
    getData();
    loadConfig();
  }, [getData, loadConfig, dispatch]);

  function parseCoordinates(obj) {
    return {
      lat: parseFloat(_.get(obj, "coordinates.lat", 0)),
      lng: parseFloat(_.get(obj, "coordinates.lng", 0))
    };
  }

  const MapNavigationStyle = {
    position: "absolute",
    right: "4px",
    bottom: "4px"
  };

  const MapNavigation = React.memo(
    () => (
      <div style={MapNavigationStyle}>
        <NavigationControl />
      </div>
    ),
    []
  );

  return (
    <>
      <Spinner loading={loading} showingSpinner={showingSpinner} />
      {!cssLoading && (
        <ReactMapGL
          ref={mapRef}
          transitionDuration={1000}
          transitionInterpolator={new FlyToInterpolator()}
          transitionEasing={easeExpInOut}
          {...mapData}
          mapStyle={mapStyle ? mapStyle : _.get(config, "mapTheme", mapTheme)}
          mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
          onViewportChange={onChangeViewport}
          onLoad={handleOnLoad}
          onTransitionStart={onTransitionStart}
          onTransitionEnd={onTransitionEnd}
          captureScroll={false}
          dragPan={isDraggable}
          scrollZoom={false}
          preventStyleDiffing={false}
          captureDoubleClick={false}
          doubleClickZoom={false}
          touchAction="pan-y"
        >
          {tiles && tiles.length > 0 && <Map highlightArea={highlightArea} />}

          {window.innerWidth > size.tablet && config.hasNavigation && <MapNavigation />}

          {step !== viewState.clusters && <Search />}

          {step === viewState.locations && config.hasFilter && <Filters />}

          {step === viewState.clusters && <InfoSlide />}

          {step !== viewState.search && config.hasBackButtons && <Navigation />}
        </ReactMapGL>
      )}
    </>
  );
};

export default MapContainer;

import styled, { keyframes } from "styled-components";

const jump = keyframes`
  0%{
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  20%{
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40%{
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  50%{
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  60%{
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
  80%{
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100%{
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;

const angry = keyframes`
0%{
  opacity: 0;
  -webkit-transform: scale(.3);
  transform: scale(.3);
}
50%{
  opacity: 1;
  -webkit-transform: scale(1.0);
  transform: scale(1.0);
}
70%{
  opacity: 1;
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}
100%{
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
`;

export const Marker = styled.div`
  width: 25px;
  height: 25px;
  transition: all 0.6s ease;
  animation: ${angry} 1s;
  opacity: 0;
  animation-delay: 0.2s;
  animation-fill-mode: forwards;

  @media ${props => props.theme.devices.laptopS} {
    width: 50px;
    height: 50px;
  }

  &:hover {
    transform: scale(1.2, 1.2);
  }

  svg,
  img,
  path:first-of-type {
    width: 100%;
    cursor: Pointer;
    fill: ${props => props.theme.colors.marker} !important;
  }
  &.jump {
    animation: ${jump} 1.8s infinite;
    animation-fill-mode: forwards;
  }
`;

import React from "react";
import * as styles from "./styles";
import {
  DefaultIcon
} from "../../../utils/icons";

function FilterCheckBox({type, icon, name, id, checked, label, onChange}) {
  return (
    <div>
      <styles.FilterCheckBox>
        <input
          onChange={onChange}
          type="checkbox"
          name={name}
          id={id}
          checked={checked}
        />
        <label htmlFor={id}>
          {icon ? (
            icon
          ) : (
            <DefaultIcon />
          )}{" "}
          {label}
        </label>
      </styles.FilterCheckBox>
    </div>
  );
}

export default FilterCheckBox;

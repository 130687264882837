import { fromJS } from "immutable";
import MAP_STYLE from "./style.json";

// For more information on data-driven styles, see https://www.mapbox.com/help/gl-dds-ref/
export const dataLayer = area =>
  fromJS({
    id: `${area}Layer`,
    source: `highlight${area}`,
    type: "fill",
    paint: {
      "fill-color": "#000",
      "fill-opacity": 0.8
    },
    minzoom: 0,
    maxzoom: 2
  });

export const defaultMapStyle = fromJS(MAP_STYLE);

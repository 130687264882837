import _ from "lodash";
import filterClusters from "./filterClusters";

/**
 */
export default function filterTile(tile, filters) {
  const clusters = _.get(tile, "clusters", []);
  return {
    ...tile,
    clusters: filterClusters(clusters, filters)
  };
}

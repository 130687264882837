import React, { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HTMLOverlay, Popup } from "react-map-gl";
import Tile from "../../components/Tile/Tile";

import ClustersContainer from "../../containers/Clusters/Clusters";
import MarkersContainer from "../../containers/Markers/Markers";

import {
  setLocation,
  resetMap,
  setDefaultViewState
} from "../../redux/actions";
import InfoWindow from "../InfoWindow/InfoWindow";
import viewState from "../../config/viewState";
import createBaseViewport from "../../utils/createBaseViewport";
import filterTiles from "../../utils/filterTiles";
import filterTile from "../../utils/filterTile";
import getTile from "../../utils/getTile";
import * as styles from "./styles";

import { size } from "../../themes/index.variables";

function Map({ highlightArea }) {
  const step = useSelector(state => state.step);
  const tiles = useSelector(state => state.tiles);
  const filters = useSelector(state => state.filters);
  const selectedLocation = useSelector(state => state.selectedLocation);
  const selectedTile = getTile(
    tiles,
    useSelector(state => state.selectedTile)
  );
  const filteredTiles = filterTiles(tiles, filters);
  const dispatch = useDispatch();
  const config = useSelector(state => state.config);
  const mapIsTransitioning = useSelector(state => state.isTransitioning);

  const anchor = window.innerWidth < size.laptopL ? "bottom" : "left";
  const popupOffsetLeft = window.innerWidth < size.laptopL ? 0 : 15;
  const popupOffsetTop = window.innerWidth < size.laptopL ? -25 : -25;

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleDefaultViewState = useCallback(() => {
    if (window.innerWidth < size.laptopS) {
      dispatch(setDefaultViewState(viewState.search));
    } else {
      dispatch(setDefaultViewState(viewState.regions));
    }
  }, [dispatch]);

  const handleResetViewport = useCallback(() => {
    handleDefaultViewState();
    if (window.innerWidth !== windowWidth) {
      dispatch(resetMap(createBaseViewport()));
      setWindowWidth(window.innerWidth);
    }
  }, [dispatch, handleDefaultViewState, windowWidth]);

  useEffect(() => {
    if (config.location || config.cluster || config.region) {
      window.removeEventListener("resize", handleResetViewport);
    } else {
      window.removeEventListener("resize", handleResetViewport);
      window.addEventListener("resize", handleResetViewport);
    }
  }, [tiles, config, handleResetViewport]);

  useEffect(() => {
    handleDefaultViewState();
  });

  const handlePopupClose = () => {
    dispatch(setLocation(null));
  };

  const redraw = ({ project }) => {
    return (
      <>
        <styles.TileContainer>
          {step === viewState.regions &&
            filteredTiles.length > 0 &&
            Object.values(filteredTiles).map((tile, i) => (
              <Tile
                key={Object.keys(tiles)[i]}
                id={tile.id}
                tile={i}
                project={project}
                highlightArea={highlightArea}
              />
            ))}
        </styles.TileContainer>
        {step === viewState.clusters && (
          <ClustersContainer
            tile={filterTile(selectedTile, filters)}
            project={project}
          />
        )}
        {(step === viewState.locations || step === viewState.search) && (
          <MarkersContainer />
        )}
        {(step === viewState.locations || step === viewState.search) &&
        selectedLocation &&
        !mapIsTransitioning ? (
          <Popup
            offsetTop={popupOffsetTop}
            offsetLeft={popupOffsetLeft}
            tipSize={10}
            anchor={anchor}
            dynamicPosition={false}
            longitude={selectedLocation.coordinates.lng}
            latitude={selectedLocation.coordinates.lat}
            closeOnClick={false}
            onClose={handlePopupClose}
            captureScroll={true}
          >
            <InfoWindow {...selectedLocation} />
          </Popup>
        ) : null}
      </>
    );
  };

  return (
    <HTMLOverlay
      redraw={redraw}
      captureScroll={false}
      captureDoubleClick={false}
    />
  );
}

export default Map;

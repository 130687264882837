import _ from "lodash";

/**
 * Get all the types available in the given clusters
 */
export default function getTypes(clusters) {
  const types = [];

  if (clusters && !Array.isArray(clusters)) {
    clusters = [clusters];
  }

  if (clusters && clusters.length > 0) {
    clusters.forEach(function(cluster) {
      Object.keys(_.get(cluster, "types", {})).forEach(function(type) {
        const locations = cluster.types[type];
        if (types.indexOf(type) === -1 && locations.length > 0) {
          types.push(type);
        }
      });
    });
  }
  return types;
}

import React, { useState } from "react";
import { useSelector } from "react-redux";
import * as styles from "./styles";
import LocationCard from "./LocationCard";

function LocationList({
  type,
  locations,
  onLocationClick,
  onLocationMouseEnter,
  onLocationMouseLeave
}) {
  const [show, setShow] = useState(false);
  const labels = useSelector(state => state.labels);
  const images = useSelector(state => state.config.images);
  const handleShowLocations = () => {
    setShow(!show);
  };

  let typeLabel;
  if (locations.length > 1) {
    typeLabel = labels[type + "s"];
  } else {
    typeLabel = labels[type];
  }

  if (!typeLabel) {
    typeLabel = type.charAt(0).toUpperCase() + type.slice(1);
  }

  return (
    <div key={type}>
      {locations.length !== 0 && (
        <styles.Type onClick={handleShowLocations}>
          {locations.length} {typeLabel}
        </styles.Type>
      )}

      {locations &&
        show &&
        locations
          .sort(function(a, b) {
            return ("" + a.name).localeCompare(b.name);
          })
          .map((location, i) => {
            return (
              <LocationCard
                key={i}
                name={location.name}
                address={location.address}
                image={location.image}
                phone={location.phone}
                onClick={() => onLocationClick(i)}
                onMouseEnter={() => onLocationMouseEnter(i)}
                onMouseLeave={() => onLocationMouseLeave(i)}
                imagePlaceholder={images[location.type + "Placeholder"]}
              />
            );
          })}
    </div>
  );
}

export default LocationList;

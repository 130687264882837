import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as styles from "./styles";
import { SearchIcon, CloseIcon, Filter2Icon, ArrowDown, BackIcon } from "../../utils/icons";
import { setSearch, resetMap } from "../../redux/actions";
import Results from "./Results";
import getSearchFilterOptions from "../../utils/getSearchFilterOptions";
import viewState from "../../config/viewState";
import createBaseViewport from "../../utils/createBaseViewport";
import { size } from "../../themes/index.variables";

function Search() {
  const tiles = useSelector(state => state.tiles);
  const step = useSelector(state => state.step);
  const labels = useSelector(state => state.labels);
  const filters = useSelector(state => state.filters);

  const dispatch = useDispatch();
  const defaultOpenState = window.innerWidth < size.laptopS;
  const [isOpened, setOpened] = useState(defaultOpenState);
  const [isFilterOpened, setFilterOpened] = useState(false);
  const [isResultsOpened, setResultsOpened] = useState(false);
  const [prevState, setPrevState] = useState(step);

  const searchInputEl = useRef(null);

  const filterOptions = getSearchFilterOptions(tiles);

  const toggleResults = () => {
    setResultsOpened(!isResultsOpened);
  };

  const openSearch = () => {
    if (isOpened) {
      close();
      if (prevState !== viewState.locations) {
        dispatch(resetMap(createBaseViewport()));
      } else {
        dispatch(
          setSearch({
            step: prevState,
            filters: {
              brand: "",
              types: filterOptions.types,
              term: "",
              searchFilter: ""
            }
          })
        );
      }
    } else {
      const newStep = step === viewState.regions ? viewState.search : step;
      setOpened(true);
      setPrevState(step);
      if (filters.searchFilter) {
        setFilterOpened(true);
      }
      dispatch(
        setSearch({
          step: newStep,
          filters: {
            brand: "",
            types: filterOptions.types,
            term: "",
            searchFilter: ""
          }
        })
      );
    }
  };

  const handleFilterChange = e => {
    const value = e.target.value.split(" ");
    const brand = e.target.value
      .split(" ")
      .splice(1)
      .join(" ");

    if (value.length > 1) {
      const filter = {
        searchFilter: value[0],
        brand
      };
      setResultsOpened(true);

      dispatch(
        setSearch({
          step: step,
          filters: filter
        })
      );
    } else {
      dispatch(
        setSearch({
          step: step,
          filters: {
            searchFilter: value[0],
            brand: "",
            types: filterOptions.types
          }
        })
      );
    }
  };

  const handleTermChange = e => {
    const value = e.target.value;
    dispatch(
      setSearch({
        filters: {
          term: value
        },
        step: step
      })
    );
  };

  const handleClearButtonClick = () => {
    dispatch(
      setSearch({
        filters: {
          term: ""
        },
        step: step
      })
    );
    searchInputEl.current.focus();
  };

  const handleShowFilter = () => {
    setFilterOpened(!isFilterOpened);
  };

  const handleInputFocus = () => {
    setResultsOpened(true);
  };

  const handleMobileIconClick = () => {
    setResultsOpened(false);
  };

  const handleInputKeyPress = function(event) {
    if (event.key === "Enter") {
      event.preventDefault();
      event.target.blur();
    }
  };

  /**
   * State changed, close the search
   */
  useEffect(() => {
    if (step !== viewState.search) {
      close();
    }
  }, [step]);

  if (!tiles) {
    return null;
  }

  return (
    tiles && (
      <styles.Container className={`${isOpened ? "show" : ""} ${isFilterOpened ? "showMore" : ""}`}>
        <styles.Open isOpened={isOpened} onClick={openSearch}>
          {!isOpened ? <SearchIcon></SearchIcon> : <CloseIcon />}
        </styles.Open>
        <styles.Filter isFilterOpened={isFilterOpened}>
          {!isFilterOpened ? (
            <div onClick={handleShowFilter}>
              <Filter2Icon />
            </div>
          ) : (
            <select
              onChange={handleFilterChange}
              value={
                filters
                  ? filters.brand
                    ? `${filters.searchFilter} ${filters.brand}`
                    : filters.searchFilter
                  : undefined
              }
            >
              <option value="">{labels.name}</option>
              <option value="brand">{labels.brand}</option>
              <optgroup label={labels.filterByType}>
                {filterOptions &&
                  filterOptions.types.map((type, i) => (
                    <option key={`${type}${i}`} value={type}>
                      {labels[type]}
                    </option>
                  ))}
              </optgroup>

              {filterOptions &&
                filterOptions.brands &&
                Object.entries(filterOptions.brands).map((type, i) => (
                  <optgroup key={`${type}${i}`} label={labels[type[0]]}>
                    {type[1].sort().map((brand, j) => (
                      <option key={`${brand}${j}`} value={type[0] + " " + brand}>
                        {brand}
                      </option>
                    ))}
                  </optgroup>
                ))}
            </select>
          )}
        </styles.Filter>
        <styles.Input>
          <div>
            <styles.MobileIcon className={Boolean(isResultsOpened) ? "back" : ""}>
              <button onClick={handleMobileIconClick}>
                <BackIcon />
                <SearchIcon />
              </button>
            </styles.MobileIcon>
            <input
              type="text"
              ref={searchInputEl}
              onChange={handleTermChange}
              onKeyPress={handleInputKeyPress}
              onFocus={handleInputFocus}
              placeholder={labels.searchPlaceholder}
              value={filters.term}
            />
            {filters.term && (
              <styles.ClearButton onClick={handleClearButtonClick}>
                <CloseIcon />
              </styles.ClearButton>
            )}
            <styles.ResultsDropdown
              onClick={toggleResults}
              className={isResultsOpened ? "show" : ""}
            >
              <ArrowDown />
            </styles.ResultsDropdown>
          </div>
        </styles.Input>
        {isResultsOpened && <Results />}
      </styles.Container>
    )
  );

  function close() {
    setOpened(false);
    setResultsOpened(false);
    setFilterOpened(false);
  }
}
export default Search;

import styled from "styled-components";

export const TileContainer = styled.div`
  position: absolute;
  bottom: 0.5em;
  left: 0;
  width: 100%;
  display: flex;
  overflow-x: auto;

  @media ${props => props.theme.devices.laptopS} {
    display: block;
    top: 0;
    left: 0;
    height: 100%;
    overflow: visible;
  }
`;

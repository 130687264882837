import _ from "lodash";
import filterCluster from "./filterCluster";

/**
 */
export default function filterClusters(clusters, filters) {
  const results = [];

  if (!_.isEmpty(clusters)) {
    clusters.forEach(cluster => {
      results.push(filterCluster(cluster, filters));
    });
  }

  return results;
}

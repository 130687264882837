import React from "react";
import { useSelector } from "react-redux";
import * as styles from "./styles";
//import loader from "../../assets/brand-loader.gif";

const Spinner = ({ loading, showingSpinner }) => {
  const images = useSelector(state => state.config.images);
  const labels = useSelector(state => state.labels);

  return (
    showingSpinner && (
      <styles.Wrapper className={loading ? "show" : ""}>
        <img src={images.loader} alt={labels.loading} />
      </styles.Wrapper>
    )
  );
};

export default Spinner;

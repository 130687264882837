import { FlyToInterpolator } from "react-map-gl";
import { easeCircleInOut } from "d3-ease";
import _ from "lodash";
import mapboxgl from "mapbox-gl";
import getCoordsFromBounds from "./getCoordsFromBounds";

export default function createClusterViewport (cluster, viewport) {
  const bounds = new mapboxgl.LngLatBounds();
  const types = _.get(cluster, 'types');

  if (types) {
    Object.entries(types).forEach(function (data) {
      const locations = data[1];
      locations.forEach(function (location) {
        const lat = _.get(location, 'coordinates.lat');
        const lng = _.get(location, 'coordinates.lng');
        if (lat && lng) {
          bounds.extend([lng, lat]);
        }
      });
    });
  }

  const { longitude, latitude, zoom } = getCoordsFromBounds({viewport, bounds, padding: 80});

  return {
    longitude: longitude,
    latitude: latitude,
    zoom: Math.min(zoom, 15),
    transitionDuration: 1700,
    transitionInterpolator: new FlyToInterpolator(),
    transitionEasing: easeCircleInOut,
    pitch: 60, // pitch in degrees
    bearing: 15 // bearing in degrees
  };
}

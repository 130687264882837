import React from "react";
import { useDispatch, useSelector } from "react-redux";
import mapboxgl from "mapbox-gl";
import * as styles from "./styles";
import Circle from "./Circle";
import Line from "./Line";
import { size } from "../../themes/index.variables";
import { setTile } from "../../redux/actions";

import Types from "./Types";
import getTile from "../../utils/getTile";
import getClusters from "../../utils/getClusters";
import getLocations from "../../utils/getLocations";
import createRegionViewport from "../../utils/createRegionViewport";

const Tile = ({ project, id, highlightArea }) => {
  const dispatch = useDispatch();

  const config = useSelector(state => state.config);
  const viewport = useSelector(state => state.viewport);
  const tiles = useSelector(state => state.tiles);

  const tileSize = {
    width: window.innerWidth > size.laptopS ? 150 : 120,
    height: 20
  };
  const tile = getTile(tiles, id);

  const {
    coordinates: { lng = null, lat = null },
    name = null,
    clusters = null
  } = tile;

  const [x, y] = Boolean(lng) && Boolean(lat) ? project([lng, lat]) : [];

  const handleTileClick = () => {
    dispatch(
      setTile({
        tile: tile.id,
        viewport: createRegionViewport(tile, viewport)
      })
    );
  };

  const handleTileHoverOn = () => {
    highlightArea(tile.highlight);
  };

  const handleTileHoverOut = () => {
    highlightArea(null);
  };

  const tileData = { x, y, size: tileSize };
  const position = { x, y };

  const bounds = new mapboxgl.LngLatBounds();

  Object.values(clusters).forEach(({ coordinates: { lat = null, lng = null } }) => {
    lat && lng && bounds.extend([lng, lat]);
  });

  let [circle_x = null, circle_y = null] =
    Boolean(bounds._sw) && Boolean(bounds._ne)
      ? project([bounds.getCenter().lng, bounds.getCenter().lat])
      : [];

  let circlePosition = React.useMemo(() => circle_x && circle_y && { x: circle_x, y: circle_y }, [
    circle_x,
    circle_y
  ]);

  if (
    !tile ||
    (tile && (!tile.coordinates.lng || !tile.coordinates.lat)) ||
    getLocations(getClusters(tile)).length === 0
  ) {
    return null;
  }

  return (
    <styles.Wrapper>
      {config.hasTiles && (
        <styles.Container
          position={position}
          size={tileSize}
          onMouseOver={handleTileHoverOn}
          onMouseLeave={handleTileHoverOut}
        >
          <styles.Tile onClick={handleTileClick}>{name}</styles.Tile>
          {config.hasTypeList && <Types clusters={clusters} tile={tile} />}
        </styles.Container>
      )}

      {config.hasCircles && circlePosition && <Circle position={circlePosition} />}

      {config.hasLines && circlePosition && <Line tile={tileData} position={circlePosition} />}
    </styles.Wrapper>
  );
};

export default Tile;

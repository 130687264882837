import React from "react";
import * as styles from "./styles";

function LocationAddress({ address }) {
  if (!address) {
    return null;
  }
  return (
    <>
      {address.addressLine.map(line => (
        <styles.LocationAddressLine key={line}>{line}</styles.LocationAddressLine>
      ))}
      <styles.LocationAddressCity>{address.cityName}</styles.LocationAddressCity>
    </>
  );
}

export default LocationAddress;

import WebMercatorViewport from "viewport-mercator-project";
import getAreaBounds from "./getAreaBounds";

/**
 * Use WebMercatorViewport to zoom the map
 * For more info check https://github.com/uber/react-map-gl/blob/master/docs/advanced/viewport-transition.md
 */
export default function getCoordsFromBounds ({viewport, bounds, padding}) {
  padding = padding || 0;
  const areaBounds = getAreaBounds(bounds);
  if (areaBounds) {
    return new WebMercatorViewport(viewport).fitBounds(
      [
        [
          areaBounds.west,
          areaBounds.south
        ],
        [
          areaBounds.east,
          areaBounds.north
        ]
      ],
      {
        padding: padding
      }
    );
  }
  return {};
}

import styled, { keyframes } from "styled-components";

export const MinimizeContainer = styled.div`
  width: 18px;
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 1;
  cursor: pointer;
  transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);

  &:hover {
    transform: scale(1.2, 1.2);
  }
`;

const showAnimation = keyframes`
 0% {
   transform: translateX(-100vw);
 }

 100% {
   transform: translateX(0px);
 }
`;

export const Container = styled.div`
  background-color: #fff;
  top: 15px;
  left: 25px;
  width: 285px;

  position: absolute;
  overflow: hidden;
  z-index: 10;
  transition: all 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  padding: 1% 2%;
  animation-name: ${showAnimation};
  animation-delay: 0.2s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  max-height: 330px;
  overflow-y: auto;
  transform: translateX(-100vw);

  @media ${props => props.theme.devices.laptopS} {
    width: 475px;
    max-height: 470px;
  }

  @media ${props => props.theme.devices.laptopM} {
    width: 510px;
    max-height: 600px;
  }

  &.show {
    max-height: 0;
  }

  &.minimized {
    width: 0;
    height: 0;
    border-radius: 50%;
    padding: 24px;
    top: 1%;
    left: 1%;

    & > * {
      display: none;
    }
    ${MinimizeContainer} {
      display: block;
      position: absolute;
    }
  }

  h1 {
    width: 120px;
    margin: 0.35em 0;
    font-weight: 900;
    font-size: 30px;
    letter-spacing: 0.08em;
    line-height: 30.24px;

    &:after {
      display: block;
      content: "";
      height: 3px;
      width: 100px;
      background-color: ${props => props.theme.colors.filterCheckMarkColor};
      margin-top: 15px;
      margin-bottom: 18px;
    }
  }
  & > p {
    margin: 0 0 40px 0;
    font-size: 16px;
    letter-spacing: 0.08em;
    line-height: 23.04px;

    span {
      color: #000;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

export const MapContainer = styled.div`
  position: absolute;
  top: -90px;
  right: -65px;
  z-index: -1;
  width: 325px;

  svg {
    fill: #e8e8e8;
    width: 100%;
  }
`;

export const CloseContainer = styled.div`
  width: 20px;
  position: absolute;
  top: 15px;
  right: 42px;
  z-index: 1;
  cursor: pointer;
  transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);

  &:hover {
    transform: scale(1.2, 1.2);
  }
`;

export const TypeContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  * {
    box-sizing: border-box;
  }
`;

export const Type = styled.div`
  width: 100%;
  padding: 10px 18px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin: 10px 7px 10px 0px;
  display: flex;
  align-items: center;
  background: #fff;
  transition: color 0.6s ease;
  color: ${props => (props.highlight ? "#000" : "rgba(0,0,0, 0.4)")};

  & > div:hover {
    text-decoration: ${props => (props.highlight ? "underline" : "none")};
    cursor: pointer;
  }

  @media ${props => props.theme.devices.laptopS} {
    width: 48%;
  }

  svg {
    width: 35px;
    height: 35px;
    padding: 6px;
    border: 1px solid rgba(0, 0, 0, 0.8);
    border-radius: 50%;
  }

  div {
    font-size: 20px;
    font-weight: bold;
    margin-left: 18px;

    span {
      display: block;
      font-weight: lighter;
      font-size: 13px;
      text-transform: uppercase;
    }
  }

  input[type="checkbox"] {
    opacity: 0;
    display: none;
  }

  input[type="checkbox"] + label:after {
    content: none;
  }

  input[type="checkbox"]:checked + label:after {
    content: "";
    border-style: red;
  }

  label {
    position: relative;
    text-transform: capitalize;
    font-weight: normal;
    display: flex;
    align-items: center;
    width: 20px;
    height: 17px;
    margin-left: auto;
    color: #000;

    &:before,
    &:after {
      position: absolute;
    }

    &:before {
      content: "";
      display: inline-block;
      height: 20px;
      width: 20px;
      border: 1px solid;
      top: -3px;
    }

    &:after {
      content: "";
      display: inline-block;
      height: 12px;
      width: 16px;
      border-left: 3px solid ${props => props.theme.colors.filterCheckMarkColor};
      border-bottom: 2px solid ${props => props.theme.colors.filterCheckMarkColor};
      transform: rotate(-45deg);
      left: 4px;
      top: -4px;
    }
  }
`;

export const Locations = styled.div`
color: #8a8a8a;
text-transform: uppercase;
font-weight: bold
font-size: 12px;
`;

export const Next = styled.div`
  text-decoration: underline;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: ${props => (props.inverted ? "0" : "18px")} !important;
  color: initial;

  &:hover {
    svg {
      fill: #fff;
      background: #000;
    }
  }

  p {
    margin: 0;
    margin-right: 5px;
    font-weight: normal;
    font-size: 17px;
    white-space: nowrap;
  }

  svg {
    transition: all 0.4s ease;
    text-decoration: underline;
    fill: #000;
    width: 25px;
    height: 25px;
    margin-top: 2px;
    margin-right: ${props => (props.inverted ? "5px" : "0")}
    transform: ${props => (props.inverted ? "rotate(180deg)" : "rotate(0deg)")}
    
  }
`;

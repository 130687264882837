import styled, { keyframes } from "styled-components";

const fade = keyframes`
from{
  opacity: 0;
  transform: translateX(-30px)
} to{
  opacity: 1;
  transform: translateX(0px)
}`;

export const Container = styled.div`
  max-width: 1920px;
  width: 100%;
  height: 90vh;
  display: flex;
  margin: 0 auto;
  position: relative;

  @media ${props => props.theme.devices.laptopS} {
    height: 500px;
  }

  @media ${props => props.theme.devices.laptopM} {
    height: 590px;
  }

  @media ${props => props.theme.devices.laptopL} {
    height: 730px;
  }

  @media ${props => props.theme.devices.desktop} {
    height: 970px;
  }

  & > div {
    width: 100% !important;
    height: 100% !important;
  }

  .mapboxgl-ctrl-bottom-right {
    display: none;
  }
  .mapboxgl-popup {
    z-index: 1;
  }
  .mapboxgl-popup-content {
    padding: 0;
    background: transparent;
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0);
  }
  .mapboxgl-popup-close-button {
    top: -33px;
    font-size: 2em;
    right: -9px;
    transition: all 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    text-shadow: 0px 0px 7px #fff;

    &:hover {
      transform: scale(1.2, 1.2);
      background-color: transparent;
    }
  }
  .overlays {
    font-family: HKGrotesk, sans-serif;
    box-sizing: border-box;
    // box-shadow: inset 0px -7px 4px #e9e9e9, inset 0px 5px 4px #e8e8e8;
  }
  & * {
    box-sizing: border-box;
  }
  .mapboxgl-popup-tip,
  .mapboxgl-popup-close-button {
    animation: ${fade} 0.7s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  .;
`;

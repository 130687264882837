import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import Map from "../Map/Map";
import * as styles from "./App.styles";
import { ThemeProvider } from "styled-components";
import globalVariables from "../../themes/index.variables";
import store from "../../redux/reducer";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let s = document.createElement("link");
    s.rel = "stylesheet";
    s.href = "https://api.tiles.mapbox.com/mapbox-gl-js/v1.3.1/mapbox-gl.css";
    let x = document.getElementsByTagName("script")[0];
    x.parentNode.insertBefore(s, x);
    s.addEventListener("load", e => {
      setLoading(false);
    });
  }, []);

  return (
    <Provider store={store}>
      <ThemeProvider theme={globalVariables}>
        <>
          <styles.Container>
            <Map cssLoading={loading} />
          </styles.Container>
        </>
      </ThemeProvider>
    </Provider>
  );
}

export default App;

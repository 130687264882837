import { FlyToInterpolator } from "react-map-gl";
import { easeCircleInOut } from "d3-ease";
import { size } from "../themes/index.variables";

export default function createLocationViewport(location) {
  const isLaptop =
    window.innerWidth < size.laptopL && window.innerWidth > size.mobileL;
  const longOffset = isLaptop ? -0.0005 : 0;
  const latOffset = isLaptop ? 0.004 : 0;

  return {
    longitude: location.coordinates.lng + longOffset,
    latitude: location.coordinates.lat + latOffset,
    zoom: 15,
    transitionDuration: 2000,
    transitionInterpolator: new FlyToInterpolator(),
    transitionEasing: easeCircleInOut,
    pitch: 60, // pitch in degrees
    bearing: 15 // bearing in degrees
  };
}

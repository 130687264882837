import _ from "lodash";

export default function getAreaBounds(bounds) {
  const southWest = _.result(bounds, 'getSouthWest');
  const northEast = _.result(bounds, 'getNorthEast');
    
  if (southWest && northEast) {
    return {
      north: northEast.lat,
      south: southWest.lat,
      east: northEast.lng,
      west: southWest.lng
    };
  }
};

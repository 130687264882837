import _ from "lodash";

export default function getLocationsByType(clusters) {
  const results = {};
  if (clusters && clusters.length > 0) {
    clusters.forEach(function(cluster) {
      const types = _.get(cluster, "types", {});

      Object.keys(types).forEach(function(type) {
        const locations = types[type];

        if (results[type] === undefined) {
          results[type] = [];
        }

        results[type].push(...locations);
      });
    });
  }
  return results;
}

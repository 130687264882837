import styled, { keyframes } from "styled-components";

const fade = keyframes`
from{
  opacity: 0;
}
to{ opacity: 1}

`;

export const Types = styled.div`
  background-color: ${props => props.theme.colors.typeListColor};
  overflow: hidden;
  transition: all 1s ease;
`;

export const Container = styled.div.attrs(({ position, size }) => ({
  style: {
    left: position.x,
    top: position.y,
    minWidth: size.width,
    minHeight: size.height
  }
}))`
  z-index: 1;

  @media ${props => props.theme.devices.tablet} {
    display: block;
  }

  @media ${props => props.theme.devices.laptopS} {
    position: absolute;
  }
`;

export const Wrapper = styled.div`
  animation: ${fade} 0.7s;
  animation-fill-mode: both;
  animation-delay: 0.3s;
  background #fff;
  margin: 0 0.5em;
  @media ${props => props.theme.devices.laptopS} {
    background: transparent;
    margin: 0;
  }
`;

export const Tile = styled.div`
  padding: 5px 10px;
  text-transform: capitalize;
  cursor: pointer;
  text-align: center;
  transition: all 0.2s ease;
  font-weight: 700;
  transform: scale(1.02, 1.02);
  font-size: 14px;
  background-color: ${props => props.theme.colors.tileColor};

  @media ${props => props.theme.devices.laptopS} {
    font-size: 16px;
    transform: scale(1.1, 1.1);
  }

  color: ${props => props.theme.colors.tileTextColor}};

  box-shadow: 1px 1px 2px ${props => props.theme.colors.tileShadowColor};

  @media ${props => props.theme.devices.tablet} {
    display: block;
    max-width: 100%;
  }

  &:hover {
    color: ${props => props.theme.colors.tileHoverTextColor};
    transform: scale(1.2, 1.2);
    background-color: ${props => props.theme.colors.tileHoverColor};
  }
`;

export const Type = styled.div`
  text-transform: capitalize;
  padding: 6px 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  border-bottom: 1px dashed #e6e6e6;
  color: ${props => props.theme.colors.typeListTextColor};
  font-weight: 700;
  font-size: 13px;

  @media ${props => props.theme.devices.laptopS} {
    font-size: 16px;
    padding: 6px 14px;
  }

  &:first-of-type {
    margin-top: 4px;
  }
  &:last-of-type {
    border-bottom: 1px dashed transparent;
  }

  &:hover {
    background: ${props => props.theme.colors.typeListHoverColor};
    color: ${props => props.theme.colors.typeListHoverTextColor};
    border-bottom: 1px solid transparent;

    svg {
      border: 1px solid rgba(255, 255, 255, 0.8);
      fill: #fff;
    }
  }

  svg {
    width: 25px;
    height: 25px;
    padding: 3px;
    border: 1px solid rgba(0, 0, 0, 0.8);
    margin-right: 5px;
    border-radius: 50%;
    display: none;

    @media ${props => props.theme.devices.laptopS} {
      display: block;
    }
  }
`;

export const Circle = styled.div.attrs(({ size, position }) => ({
  style: {
    width: size.width,
    height: size.height,
    top: position.y,
    left: position.x
  }
}))`
  background: ${props => props.theme.colors.lineColor} !important;
  border-radius: 50%;
  position: absolute;
  display: none;
  @media ${props => props.theme.devices.laptopS} {
    display: block;
  }
`;

export const Line = styled.div.attrs(({ size, border, position, theme }) => ({
  style: {
    width: size.width,
    height: size.height,
    top: position.y,
    left: position.x,
    borderBottom: border.borderBottom + "px solid " + theme.colors.lineColor,
    borderRight: border.borderRight + "px solid " + theme.colors.lineColor,
    borderTop: border.borderTop + "px solid " + theme.colors.lineColor,
    borderLeft: border.borderLeft + "px solid " + theme.colors.lineColor
  }
}))`
  z-index: 0;
  position: absolute;
  display: none;
  -moz-border-radius: 0%;
  -webkit-border-radius: 0%;
  border-radius: 0%;

  @media ${props => props.theme.devices.tablet} {
    display: block;
  }
`;

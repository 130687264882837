import interpolate from "interpolate-range";
import { FlyToInterpolator } from "react-map-gl";
import { easeCircleInOut } from "d3-ease";

export default function createBaseViewport() {

  /**
   * Merge in defaults
   */
  const viewport = {
    transitionDuration: 2100,
    latitude: 20,
    longitude: 0,
    pitch: 0, // pitch in degrees
    bearing: 0, // bearing in degrees
    transitionInterpolator: new FlyToInterpolator(),
    transitionEasing: easeCircleInOut,
  };



  //Reset the map when the windows is resized
  if (window) {
    const zoomInterpolate = interpolate({
      // Interpolation created to set the google maps zoom based on the width of the page
      // It interpolates the width to zoom units
      inputRange: [375, 1920],
      outputRange: [0, 1.9],
      clamp: true
    });

    var width = window.innerWidth > 1920 ? 1920 : window.innerWidth;
    viewport.zoom = Math.round(zoomInterpolate(width) * 100) / 100;
  }

  return viewport;
}

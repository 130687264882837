import _ from "lodash";

/**
 */
export default function filterClusters(cluster, filters) {
  const types = _.get(cluster, "types", {});
  const newTypes = {};
  const filterTypes = _.get(filters, "types", []);
  const searchFilter = _.get(filters, "searchFilter", "");
  const term = _.get(filters, "term", "").toLowerCase();
  const brand = _.get(filters, "brand", "").toLowerCase();

  Object.keys(types).forEach(function(type) {
    const typeCheck = type.toLowerCase();
    const locations = types[type];
    const newLocations = (newTypes[type] = []);

    // Check against type filter
    if (filterTypes.indexOf(type) !== -1) {
      locations.forEach(function(location) {
        /**
         * If a search term is provided, ensure that the location has it somewhere
         */
        if (term) {
          let containsTerm = false;
          let checks = [
            typeCheck,
            _.get(location, "name", "").toLowerCase(),
            _.get(location, "brand", "").toLowerCase(),
            _.get(location, "address.cityName", "").toLowerCase(),
            ..._.get(location, "address.addressLine", []).map(line => {
              return line.toLowerCase();
            })
          ];

          if (searchFilter === "brand") {
            // Searching across brand only
            checks = [_.get(location, "brand", "").toLowerCase()];
          }

          let i;
          for (i = 0; i < checks.length; i++) {
            if (checks[i].includes(term)) {
              containsTerm = true;
            }
          }

          if (!containsTerm) {
            return false;
          }
        }

        /**
         * If a type is provided ensure that this location is that type
         */
        if (searchFilter && searchFilter !== "brand" && searchFilter !== type) {
          return false;
        }

        if (brand && _.get(location, "brand", "").toLowerCase() !== brand) {
          return false;
        }

        /**
         * Loation is good, push it in
         */
        newLocations.push({ ...location });
      });
    }
    newTypes[type] = newLocations;
  });

  return {
    ...cluster,
    types: newTypes
  };
}

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Marker } from "react-map-gl";
import _ from "lodash";

import { setLocation } from "../../redux/actions";

import * as MarkerComponent from "../../components/Marker/Marker";

import viewState from "../../config/viewState";
import { size } from "../../themes/index.variables";

import getCluster from "../../utils/getCluster";
import getClusters from "../../utils/getClusters";
import filterTiles from "../../utils/filterTiles";
import filterClusters from "../../utils/filterClusters";
import filterCluster from "../../utils/filterCluster";
import getTile from "../../utils/getTile";
import getTypes from "../../utils/getTypes";
import getLocations from "../../utils/getLocations";
import createLocationViewport from "../../utils/createLocationViewport";

export default function MarkersContainer() {
  const markers = [];
  const step = useSelector(state => state.step);
  const tiles = useSelector(state => state.tiles);
  const defaultTypes = getTypes(getClusters(tiles));
  const filters = useSelector(state => state.filters);
  const selectedTile = getTile(
    tiles,
    useSelector(state => state.selectedTile)
  );
  const selectedCluster = getCluster(
    selectedTile,
    useSelector(state => state.selectedCluster)
  );

  const dispatch = useDispatch();

  const offsetLeft = window.innerWidth < size.laptopS ? -10 : -25;
  const offsetTop = window.innerWidth < size.laptopS ? -25 : -50;

  const handleClusterClick = function(cluster) {
    const locations = getLocations(cluster);
    if (locations.length > 0) {
      const location = locations[0];
      if (_.get(location, "coordinates.lat") && _.get(location, "coordinates.lng")) {
        dispatch(
          setLocation({
            location,
            clusterId: location.clusterId,
            filters: {
              types: defaultTypes,
              term: "",
              searchFilter: "",
              brand: ""
            },
            viewport: createLocationViewport(location)
          })
        );
      }
    }
  };

  const handleLocationClick = function(location) {
    if (_.get(location, "coordinates.lat") && _.get(location, "coordinates.lng")) {
      if (step === viewState.search) {
        dispatch(
          setLocation({
            location,
            clusterId: location.clusterId,
            viewport: createLocationViewport(location)
          })
        );
      } else {
        dispatch(
          setLocation({
            location,
            viewport: createLocationViewport(location)
          })
        );
      }
    }
  };

  if (step === viewState.search) {
    const clusters = getClusters(filterTiles(tiles, filters));
    clusters.forEach(function(cluster) {
      if (getLocations(cluster).length > 0) {
        markers.push(
          <Marker
            key={`marker-${cluster.id}`}
            latitude={cluster.coordinates.lat}
            longitude={cluster.coordinates.lng}
            offsetLeft={offsetLeft}
            offsetTop={offsetTop}
          >
            <MarkerComponent.default
              location={cluster}
              onClick={() => handleClusterClick(cluster)}
            />
          </Marker>
        );
      }
    });
  } else if (step === viewState.locations) {
    let locations = [];

    if (selectedCluster) {
      locations = getLocations(filterCluster(selectedCluster, filters));
    } else if (selectedTile) {
      locations = getLocations(filterClusters(getClusters(selectedTile), filters));
    }

    locations.forEach(function(location) {
      const lat = _.get(location, "coordinates.lat");
      const lng = _.get(location, "coordinates.lng");
      if (Boolean(lat) && Boolean(lng)) {
        markers.push(
          <Marker
            key={`marker-${location.id}`}
            latitude={lat}
            longitude={lng}
            offsetLeft={offsetLeft}
            offsetTop={offsetTop}
          >
            <MarkerComponent.default
              location={location}
              onClick={() => handleLocationClick(location)}
            />
          </Marker>
        );
      }
    });
  }

  if (markers.length > 0) {
    return (
      <>
        {markers.map((marker, j) => {
          return marker;
        })}
      </>
    );
  }

  return null;
}

import styled, { keyframes } from "styled-components";

const fall = keyframes`


	0% {
        transform: translateY(-200%) translateX(-50%);
  
	
	}

	4% {
        transform: translateY(-189.11%) translateX(-50%);
	
	}

	8% {
        transform: translateY(-156.44%) translateX(-50%);

	}

	18% {

        transform: translateY(-101.99%) translateX(-50%);
	
	}

	26% {
        transform: translateY(-124.98%) translateX(-50%);
		
	}

	46% {
        transform: translateY(-101.63%) translateX(-50%);
	}

	64% {
		transform: translateY(-106.25%) translateX(-50%);
	}

	76% {
        transform: translateY(-101.66%) translateX(-50%);
	}

	88% {
        transform: translateY(-101.54%) translateX(-50%);
	}

	100% {
        transform: translateY(-100%) translateX(-50%);
   
	}

`;

const fade = keyframes`
from{
    opacity: 0;
}
to{ opacity: 1}
`;

export const Container = styled.div.attrs(props => ({
  style: {
    top: props.position.y + "px",
    left: props.position.x + "px"
  }
}))`
  width: 0;
  height: 0;
  position: absolute;
  cursor: pointer;
  overflow: visible;
  transition: transform 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transform-origin: 50% 50%;

  &:hover {
    transform: scale(1.2);
  }

  &:after {
    content: "";
    display: block;
    pointer-events: none;
    position: absolute;
    width: 40px;
    height: 10px;
    transform: translate(-50%, 3px) translateZ(0);
    left: 0%;
    background-image: radial-gradient(
      ellipse at center,
      rgba(0, 0, 0, 0.15) 0,
      rgba(0, 0, 0, 0) 80%
    );
    z-index: 5;
  }

  svg,
  img {
    animation: ${fall} 0.8s;
    animation-fill-mode: both;
    animation-delay: 0.3s;
    fill: ${props => props.theme.colors.clusterColor} !important;

    position: absolute;
    width: 30px;
    height: 47.14px;

    @media ${props => props.theme.devices.laptopL} {
      width: 40px;
      height: 64.14px;
    }
  }
  img {
    top: -24px;
  }
`;

export const Count = styled.div`
  position: absolute;
  top: -41px;
    left: -34.5px;
    width: 70px;
    text-align: center;
  font-family: font-family: ${props => props.theme.fonts.sansSerifStack};
  font-weight: lighter;
  animation: ${fade} .4s;
  animation-fill-mode: both;
  animation-delay: 0.9s;
  font-size: 16px;
  letter-spacing: 0;
  font-weight: 700;

  @media ${props => props.theme.devices.laptopL} {
    width: 40px;
    left: -20px;
    font-size: 16px;
    top: -53px;
  }

  @media ${props => props.theme.devices.desktop} {
    width: 40px;
    left: -20px;
    font-size: 16px;
    top: -53px;
  }

`;

const fadeIn = keyframes`


	0% {opacity: 0;
		transform: translateX(100%);
	}

	16% {
		transform: translateX(68%);
	}

	28% {
		transform: translateX(14%);
	}

	44% {
		transform: translateX(-4.63%);
	}

	59% {opacity: 1;
		transform: translateX(1.64%);
	}

	73% {
		transform: translateX(-0.58%);
	}

	88% {
		transform: translateX(1.2%);
	}

	100% {
		transform: translateX(0%);
	}




`;

export const Info = styled.div`
  padding: 5px 10px;
  background-color: #fff;
  display: inline-block;
  white-space: nowrap;
  position: absolute;
  top: -44px;
  left: 38px;
  animation: ${fadeIn} 0.5s;
  animation-delay: 1.2s;
  animation-fill-mode: both;
  animation-direction: alternate;
  z-index: 10;
  font-weight: 700;

  @media ${props => props.theme.devices.laptopL} {
    top: -55px;
  }

  p {
    margin: 0;
    margin-left: -5px;
    font-size: 9px;

    @media ${props => props.theme.devices.laptopS} {
      font-size: 16px;
    }
  }

  &:before {
    content: "";
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    top: 0px;
    left: -15.8px;
    border-right: 16px solid #fff;

    @media ${props => props.theme.devices.laptopS} {
      border-top: 14px solid transparent;
      border-bottom: 14px solid transparent;
    }
  }
`;

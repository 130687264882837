import styled from "styled-components";

export const Filter = styled.span`
margin-left: 30px;
text-decoration: none
margin-right: 6px;
font-weight: normal;
white-space: nowrap;
`;

export const Location = styled.span`
  white-space: nowrap;

  span {
    font-weight: bold;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`;
export const FilterContainer = styled.div`
  content: "Filters";
  background-color: #fff;
  color: #000;
  padding: 4px 8px;
  border-radius: 30px;
  display: block;
  height: 31px;
  max-width: ${props => (props.isFilterOpened ? "0" : "900px")};
  position: absolute;
  z-index: 0;
  left: 37px;
  top: 11px;
  overflow: hidden;
  transition: all 0.5s ease-in;
  display: flex;
  justify-content: flex-end;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  @media ${props => props.theme.devices.tablet} {
    bottom: auto;
    left: 25px;
    top: 105px;
    padding: 8px 10px;
    font-size: 16px;
  }
`;

export const ButtonCircle = styled.div`
  div:first-of-type {
    background-color: #000;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    position: absolute;
    left: 25px;
    top: 7px;
    cursor: pointer;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
    transition: all 0.5s ease;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;

    @media ${props => props.theme.devices.tablet} {
      bottom: auto;
      left: 10px;
      top: 100px;
    }

    &:hover {
      background-color: ${props => props.theme.colors.filterTextColor} svg {
        fill: ${props => props.theme.colors.filterColor};
      }
    }
  }

  svg {
    transition: all 0.5s ease;
    width: 25px;
    fill: ${props => props.theme.colors.filterColor};
  }
`;

export const Close = styled.div`
  position: relative;
  top: 15px;
  cursor: pointer;
  transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  width: 20px;
  height: 14px;
  display: block;
  transition: all 0.2s ease-in;
  margin-left: auto;
  margin-top: -24px;

  &:hover {
    transform: scale(1.2, 1.2);
  }

  @media ${props => props.theme.devices.tablet} {
    top: 15px;
  }

  svg {
    fill: ${props => props.theme.colors.filterTextColor};
  }
`;

export const Wrapper = styled.div`
    position: absolute;
    z-index: 2;
    background-color: ${props => props.theme.colors.filterColor};
      
    color: ${props => props.theme.colors.filterTextColor}
    width: 100%;
    max-height: 0%;
    transition: all 0.8s cubic-bezier(0.645, 0.045, 0.355, 1);
    overflow: scroll;
    -ms-overflow-style: none;
 

    & > h2{
      font-weight: 500;
      font-size: 18px;
      letter-spacing: .08em;
      line-height: 30.24px;
      display: block;
      margin: 0.5em 0 0 0;

      @media ${props => props.theme.devices.laptopL} {
        font-size: 24px;
      }
    }

    & > p {
      margin-left: 20px;
      &:after {
        display: block;
        content: "";
        height: 2px;
        width: 100px;
        background-color: ${props => props.theme.colors.infoWindowTextType};
        margin-top: 15px;
        margin-bottom: 38px;
      }
    }

    & > h1{
      font-weight: bold;
      max-width: 280px;
      margin: 0;
      font-size: 28px;
      @media ${props => props.theme.devices.laptopL} {
        font-size: 32px;
      }
  }
  & > p:not(:first-of-type){
      margin-top: 30px;
  }

  &> svg{
    z-index: -1;
    position: absolute;
    top: -38%;
    right: -48%;
    width: 260px;
    display: none;

    @media ${props => props.theme.devices.desktop} {
      width: 430px;
      top: -25%;
      right: -36%;
    }
  }

  @media ${props => props.theme.devices.tablet} {
      display: block;
      max-width: 300px;
      max-height: 80%;
      position: absolute;
      top: 145px;
      left: 2%;
      bottom; 0;
      padding: 1em;

      @media ${props => props.theme.devices.laptopL} {
        max-width: 420px;
        padding: 2em;
      }
  
      transition: all 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
      overflow: auto;
      transform: translateX(-100vw);

      &.show{
        transform: translateX(0vw);
      }
    }
`;

export const Reset = styled.div`
  text-transform: uppercase;
  position: relative;
  top: 10px;
  right: 28px;
  display: block;
  width: 72px;
  margin-left: auto;
  margin-right: 10px;
  font-size: 12px;
  cursor: pointer;
  padding: 10px 0;

  @media ${props => props.theme.devices.tablet} {
    padding: 0;
  }

  &:hover {
    text-decoration: underline;
  }
`;

export const Message = styled.p`
  text-align: center;
  font-weight: light;
  margin: 20px;
`;

export const Region = styled.h1`
  margin-left: 20px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const Locations = styled.div``;

export const Container = styled.div`
  display: none;

  @media ${props => props.theme.devices.laptopS} {
    display: block;
  }

  ${ButtonCircle} {
    display: block;
  }
`;

export const Recenter = styled.div`
  position: relative;
  top: 18px;
  cursor: pointer;
  transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  width: 20px;
  height: 14px;
  display: block;
  transition: all 0.2s ease-in;
  margin-left: auto;
  margin-top: -24px;
  right: 27px;

  &:hover {
    transform: scale(1.2, 1.2);
  }

  @media ${props => props.theme.devices.tablet} {
    top: 25px;
  }

  svg {
    fill: ${props => props.theme.colors.filterTextColor};
  }
`;

import _ from "lodash";

export default function getClusterByName(tiles, name) {
  name = name.toLowerCase();
  let search = null;
  if (tiles && name) {
    Object.values(tiles).find(tile => {
      return Object.values(tile.clusters).find(cluster => {
        if (_.get(cluster, 'name', "").toLowerCase() === name) {
          return search = cluster;
        }
        return false;
      });
    });
  }
  return search;
}

import styled, { keyframes } from "styled-components";
import { rgba } from "polished/lib/index";

const fade = keyframes`
from{
  opacity: 0;
} to{
  opacity: 1;
}`;

export const Button = styled.p`
  cursor: pointer;
  font-size: 10px;

  &:hover {
    text-decoration: underline;
  }
  & ~ p {
    display: ${props => (props.isOpened ? "none" : "flex")};
  }
`;
export const Location = styled.button`
  display: flex;
  flex-direction: column;
  background-color: ${props => rgba(props.theme.colors.filterLocationColor, 0.7)};
  width: 100%;
  padding: 0;
  border: 0;
  cursor: pointer;
  animation: ${fade} 0.7s ease-in;
  transition: background-color 0.3s ease-in;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: #e0e0e0;
  text-align: inherit;
  margin-left: 0.5em;
  margin-bottom: 0;

  &:first-child {
    margin-left: 0;
  }

  &:hover {
    box-shadow: 1px 1px 3px #e8e8e8;
    background-color: ${props => props.theme.colors.filterLocationHoverColor};
  }
  @media ${props => props.theme.devices.laptopS} {
    flex-direction: row;
    margin-bottom: 18px;
    margin-left: 0;
    border: 0;
  }
`;

export const LocationImageWrapper = styled.div`
  flex: 1;
  align-self: stretch;
  justify-self: stretch;
`;

export const LocationImage = styled.div`
  width: 150px;
  height: 125px;
  min-width: 112px;
  overflow: hidden;
  position: relative;

  @media ${props => props.theme.devices.laptopS} {
    width: auto;
    height: 100%;

    img {
      transform: translate(-25%);
    }
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    max-height: 100%;
  }
`;

export const LocationInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0.5em;
  @media ${props => props.theme.devices.laptopS} {
    padding: 10px 23px;
    border-width: 1px 1px 1px 0;
    border-style: solid;
    border-color: #e0e0e0;
    min-height: 150px;
  }
  h2 {
    margin: 0;
    color: ${props => props.theme.colors.filterLocationTextColor};

    font-size: 13px;

    letter-spacing: 0.05em;
    line-height: 15px;
    margin-bottom: 5px;

    @media ${props => props.theme.devices.laptopS} {
      font-size: 16px;
      letter-spacing: 0.07em;
      line-height: 20px;
      margin-bottom: 8px;
    }
  }
  p {
    margin: 0;
    color: ${props => props.theme.colors.filterLocationTextColor};
    font-size: 12px;
    letter-spacing: 0.02em;
    line-height: 18px;
    color: #444;

    @media ${props => props.theme.devices.laptopS} {
      font-size: 13px;
      justify-content: center;
      align-self: stretch;
    }
  }
`;

export const LocationAddressLine = styled.p`
  display: none;
  @media ${props => props.theme.devices.laptopS} {
    display: block;
  }
`;

export const LocationAddressCity = styled.p``;

export const LocationAddressPhone = styled.p`
  display: none;
  @media ${props => props.theme.devices.laptopS} {
    display: block;
  }
`;

export const Type = styled.button`
  text-transform: uppercase;
  padding: 10px;
  font-weight: bold;
  font-size: 14px;
  background: #e8e8e8;
  margin-bottom: 6px;
  transition: all 0.6s ease;
  display: block;
  border: 0;
  width: 100%;
  text-align: left;

  cursor: pointer;

  &:hover {
    background: #585858;
    color: #fff;
    transform: scale(1.02, 1.02);
  }
`;

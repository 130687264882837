export const defaultTheme = {
  mapBackground: "#e8e8e8",
  buttonColor: "#000",
  buttonTextColor: "#fff",
  filterColor: "#FFF",
  filterTextColor: "#3c3c3c",
  filterLocationColor: "#fff",
  filterLocationTextColor: "#4b4e58",
  filterLocationHoverColor: "#efefef",
  filterCheckMarkColor: "#885A28",
  tileShadowColor: "#3c3c3c",
  tileColor: "#885A28",
  tileHoverColor: "#3c3c3c",
  tileTextColor: "#fff",
  tileHoverTextColor: "#fff",
  lineColor: "#cf9459",
  circleColor: "#cf9459",
  typeListColor: "#fff",
  typeListTextColor: "#555",
  typeListHoverColor: "#3c3c3c",
  typeListHoverTextColor: "#fff",
  countriesHoverColor: "#3c3c3c",
  infoWindowColor: "#fff",
  infoWindowButtonRightColor: "#888786",
  infoWindowButtonRightTextColor: "#fff",
  infoWindowButtonLeftColor: "#7D5024",
  infoWindowButtonLeftTextColor: "#fff",
  infoWindowTextColor: "#7D5024",
  infoWindowCloseColor: "#7D5024",
  infoWindowCloseTextColor: "#fff",
  filterBreadCrumbColor: "#FFF",
  filterBreadCrumbTextColor: "#3c3c3c",
  clusterColor: "#000",
  marker: "#885A28",
  mapTheme: "mapbox://styles/verb-mapbox/ck0xunxotjpoj1dl7fz1u2lnq"
};

export const southBeachTheme = {
  mapBackground: "#e8e8e8",
  buttonColor: "#FFA400",
  buttonTextColor: "#fff",
  filterColor: "#fff",
  filterTextColor: "#000",
  filterLocationColor: "#e5e5e5",
  filterCheckMarkColor: "#FFA400",
  filterLocationTextColor: "#000000",
  filterLocationHoverColor: "#e1e1e1",
  tileShadowColor: "#000000",
  tileColor: "#000000",
  tileHoverColor: "#DE8F02",
  tileTextColor: "#fff",
  tileHoverTextColor: "#fff",
  lineColor: "#FFA400",
  circleColor: "#FFA400",
  typeListColor: "#fff",
  typeListTextColor: "#000000",
  typeListHoverColor: "#FFA400",
  typeListHoverTextColor: "#fff",
  countriesHoverColor: "#a86d00",
  infoWindowColor: "#fff",
  infoWindowButtonRightColor: "#D8D8D8",
  infoWindowButtonRightTextColor: "#fff",
  infoWindowButtonLeftColor: "#FFA400",
  infoWindowButtonLeftTextColor: "#fff",
  infoWindowTextColor: "#FFA400",
  infoWindowCloseColor: "#FFA400",
  infoWindowCloseTextColor: "#fff",
  filterBreadCrumbColor: "#FFA400",
  filterBreadCrumbTextColor: "#fff",
  clusterColor: "#FFA400",
  marker: "#FFA400",
  mapTheme: "mapbox://styles/verb-mapbox/ck2f8ft2n0tts1cqmjeanntlt"
};

export const mondrianDolla = {
  mapBackground: "#e8e8e8",
  buttonColor: "#742225",
  buttonTextColor: "#fff",
  filterColor: "#fff",
  filterShadow: "#2B211A",
  filterTextColor: "#2B211A",
  filterCheckMarkColor: "#c9454a",
  filterLocationColor: "#c3b0ac",
  filterLocationTextColor: "#2B211A",
  filterLocationHoverColor: "#e1e1e1",
  tileShadowColor: "#2B211A",
  tileColor: "#2B211A",
  tileHoverColor: "#742225",
  tileTextColor: "#fff",
  tileHoverTextColor: "#fff",
  lineColor: "#c9454a",
  circleColor: "#c9454a",
  typeListColor: "#fff",
  typeListTextColor: "#2B211A",
  typeListHoverColor: "#742225",
  typeListHoverTextColor: "#fff",
  countriesHoverColor: "#742225",
  infoWindowColor: "#fff",
  infoWindowButtonRightColor: "#7A7A79",
  infoWindowButtonRightTextColor: "#fff",
  infoWindowButtonLeftColor: "#742225",
  infoWindowButtonLeftTextColor: "#fff",
  infoWindowTextColor: "#742225",
  infoWindowCloseColor: "#742225",
  infoWindowCloseTextColor: "#fff",
  filterBreadCrumbColor: "#c9454a",
  filterBreadCrumbTextColor: "#FFF",
  clusterColor: "#fa141c",
  marker: "#fa141c",
  mapTheme: "mapbox://styles/verb-mapbox/ck2f8x36z322w1cql2hcoygbq"
};

export const theRedbury = {
  mapBackground: "#e8e8e8",
  buttonColor: "#D4D44C",
  buttonTextColor: "#012818",
  filterColor: "#fff",
  filterTextColor: "#012818",
  filterLocationColor: "#D4D44C",
  filterCheckMarkColor: "#AF1612",
  filterLocationTextColor: "#012818",
  filterLocationHoverColor: "#e1e1e1",
  tileShadowColor: "#012818",
  tileColor: "#012818",
  tileHoverColor: "#AF1612",
  tileTextColor: "#fff",
  tileHoverTextColor: "#fff",
  lineColor: "#AF1612",
  circleColor: "#AF1612",
  typeListColor: "#FFF9DA",
  typeListTextColor: "#012818",
  typeListHoverColor: "#E29C15",
  typeListHoverTextColor: "#fff",
  countriesHoverColor: "#AF1612",
  infoWindowColor: "#FFF9DA",
  infoWindowButtonRightColor: "#D4D44C",
  infoWindowButtonRightTextColor: "#012818",
  infoWindowButtonLeftColor: "#AF1612",
  infoWindowButtonLeftTextColor: "#fff",
  infoWindowTextColor: "#466319",
  infoWindowCloseColor: "#AF1612",
  infoWindowCloseTextColor: "#fff",
  filterBreadCrumb: "#012818",
  filterBreadCrumbColor: "#AF1612",
  filterBreadCrumbTextColor: "#fff",
  mapColor: "#774c4b",
  clusterColor: "#fa141c",
  ocean: "#b28585",
  marker: "#fa141c",
  mapTheme: "mapbox://styles/verb-mapbox/ck2f98ejw2b351cmtpvolb1ok"
};

export const hydeMidtown = {
  mapBackground: "#e8e8e8",
  buttonColor: "#EAB9AB",
  buttonTextColor: "#fff",
  filterColor: "#fff",
  filterCheckMarkColor: "#d7b52d",
  filterTextColor: "#56695D",
  filterLocationColor: "#9dafa3",
  filterLocationTextColor: "#56695D",
  filterLocationHoverColor: "#e1e1e1",
  tileShadowColor: "#3c3c3c",
  tileColor: "#DFC354",
  tileHoverColor: "#AE762E",
  tileTextColor: "#617468",
  tileHoverTextColor: "#fff",
  lineColor: "#d67900",
  circleColor: "#d67900",
  typeListColor: "#fff",
  typeListTextColor: "#3c3c3c",
  typeListHoverColor: "#93A399",
  typeListHoverTextColor: "#fff",
  countriesHoverColor: "#DFC354",
  infoWindowColor: "#fff",
  infoWindowButtonRightColor: "#E3EAE6",
  infoWindowButtonRightTextColor: "#617468",
  infoWindowButtonLeftColor: "#DFC354",
  infoWindowButtonLeftTextColor: "#56695D",
  infoWindowTextColor: "#93A399",
  infoWindowCloseColor: "#DFC354",
  infoWindowCloseTextColor: "#fff",
  filterBreadCrumbColor: "#d7b52d",
  filterBreadCrumbTextColor: "#FFF",
  mapColor: "#9f8f56",
  clusterColor: "#ffcd1a",
  ocean: "#c9c09c",
  marker: "#ffcd1a",
  mapTheme: "mapbox://styles/verb-mapbox/ck2f9p4200xcl1crk9xfe4qxt"
};

export const sanderson = {
  mapBackground: "#e8e8e8",
  buttonColor: "#2B211A",
  buttonTextColor: "#fff",
  filterColor: "#fff",
  filterTextColor: "#2B211A",
  filterCheckMarkColor: "#3A0C57",
  filterLocationColor: "#e5e5e5",
  filterLocationTextColor: "#2B211A",
  filterLocationHoverColor: "#e1e1e1",
  tileShadowColor: "#2B211A",
  tileColor: "#2B211A",
  tileHoverColor: "#470F6B",
  tileTextColor: "#fff",
  tileHoverTextColor: "#fff",
  lineColor: "#470F6B",
  circleColor: "#470F6B",
  typeListColor: "#fff",
  typeListTextColor: "#2B211A",
  typeListHoverColor: "#470F6B",
  typeListHoverTextColor: "#fff",
  countriesHoverColor: "#470F6B",
  infoWindowColor: "#fff",
  infoWindowButtonRightColor: "#7A7A79",
  infoWindowButtonRightTextColor: "#fff",
  infoWindowButtonLeftColor: "#470F6B",
  infoWindowButtonLeftTextColor: "#fff",
  infoWindowTextColor: "#470F6B",
  infoWindowCloseColor: "#470F6B",
  infoWindowCloseTextColor: "#fff",
  filterBreadCrumbColor: "#fff",
  filterBreadCrumbTextColor: "#3A0C57",
  mapColor: "#9581a2",
  clusterColor: "#da58fe",
  ocean: "#c4b9cb",
  marker: "#da58fe",
  mapTheme: "mapbox://styles/verb-mapbox/ck2fdj0n4014x1cp4ua4x5inp"
};

export const delano = {
  mapBackground: "#e8e8e8",
  buttonColor: "#00728E",
  buttonTextColor: "#fff",
  filterColor: "#fff",
  filterTextColor: "#343434",
  filterCheckMarkColor: "#00afdb",
  filterLocationColor: "#e5e5e5",
  filterLocationTextColor: "#343434",
  filterLocationHoverColor: "#e1e1e1",
  tileShadowColor: "#343434",
  tileColor: "#343434",
  tileHoverColor: "#00afdb",
  tileTextColor: "#fff",
  tileHoverTextColor: "#fff",
  lineColor: "#00afdb",
  circleColor: "#00afdb",
  typeListColor: "#fff",
  typeListTextColor: "#343434",
  typeListHoverColor: "#00728E",
  typeListHoverTextColor: "#fff",
  countriesHoverColor: "#00728E",
  infoWindowColor: "#fff",
  infoWindowButtonRightColor: "#6C7175",
  infoWindowButtonRightTextColor: "#fff",
  infoWindowButtonLeftColor: "#00728E",
  infoWindowButtonLeftTextColor: "#fff",
  infoWindowTextColor: "#00728E",
  infoWindowCloseColor: "#00728E",
  infoWindowCloseTextColor: "#fff",
  filterBreadCrumbColor: "#00afdb",
  filterBreadCrumbTextColor: "#fff",
  mapColor: "#5d838e",
  clusterColor: "#33d6ff",
  ocean: "#afc5cb",
  marker: "#33d6ff",
  mapTheme: "mapbox://styles/verb-mapbox/ck2fdorlf01aa1ctezopuwsjv"
};

export const shoreClub = {
  mapBackground: "#e8e8e8",
  buttonColor: "#061F62",
  buttonTextColor: "#fff",
  filterColor: "#fff",
  filterTextColor: "#626262",
  filterCheckMarkColor: "#061F62",
  filterLocationColor: "#D8D8D8",
  filterLocationTextColor: "#626262",
  filterLocationHoverColor: "#e1e1e1",
  tileShadowColor: "#626262",
  tileColor: "#05194F",
  tileHoverColor: "#0b38b1",
  tileTextColor: "#fff",
  tileHoverTextColor: "#fff",
  lineColor: "#0b38b1",
  circleColor: "#0b38b1",
  typeListColor: "#fff",
  typeListTextColor: "#626262",
  typeListHoverColor: "#061F62",
  typeListHoverTextColor: "#fff",
  countriesHoverColor: "#061F62",
  infoWindowColor: "#fff",
  infoWindowButtonRightColor: "#6C7175",
  infoWindowButtonRightTextColor: "#fff",
  infoWindowButtonLeftColor: "#061F62",
  infoWindowButtonLeftTextColor: "#fff",
  infoWindowTextColor: "#061F62",
  infoWindowCloseColor: "#061F62",
  infoWindowCloseTextColor: "#fff",
  filterBreadCrumbColor: "#061F62",
  filterBreadCrumbTextColor: "#fff",
  mapColor: "#66708f",
  clusterColor: "#29a2ff",
  ocean: "#abb2c4",
  marker: "#29a2ff",
  mapTheme: "mapbox://styles/verb-mapbox/ck2fdt2hn01el1cp8sjbuztml"
};

export const hudson = {
  mapBackground: "#e8e8e8",
  buttonColor: "#C7D902",
  buttonTextColor: "#2B211A",
  filterColor: "#fff",
  filterTextColor: "#2B211A",
  filterCheckMarkColor: "#C7D902",
  filterLocationColor: "#D8D8D8",
  filterLocationTextColor: "#2B211A",
  filterLocationHoverColor: "#e1e1e1",
  tileShadowColor: "#2B211A",
  tileColor: "#C7D902",
  tileHoverColor: "#eafd1c",
  tileTextColor: "#2B211A",
  tileHoverTextColor: "#2B211A",
  lineColor: "#eafd1c",
  circleColor: "#eafd1c",
  typeListColor: "#fff",
  typeListTextColor: "#2B211A",
  typeListHoverColor: "#C7D902",
  typeListHoverTextColor: "#2B211A",
  countriesHoverColor: "#99a702",
  infoWindowColor: "#fff",
  infoWindowButtonRightColor: "#2B211A",
  infoWindowButtonRightTextColor: "#fff",
  infoWindowButtonLeftColor: "#C7D902",
  infoWindowButtonLeftTextColor: "#2B211A",
  infoWindowTextColor: "#99a702",
  infoWindowCloseColor: "#C7D902",
  infoWindowCloseTextColor: "#2B211A",
  filterBreadCrumbColor: "#C7D902",
  filterBreadCrumbTextColor: "#2B211A",
  mapColor: "#aab15d",
  clusterColor: "#eafd1c",
  ocean: "#c9cd98",
  marker: "#fa141c",
  mapTheme: "mapbox://styles/verb-mapbox/ck2fdyxc101ks1cp654s8h5np"
};

export const stMartinsLane = {
  mapBackground: "#e8e8e8",
  buttonColor: "#E2E738",
  buttonTextColor: "#2B211A",
  filterColor: "#fff",
  filterTextColor: "#2B211A",
  filterCheckMarkColor: "#768104",
  filterLocationColor: "#D8D8D8",
  filterLocationTextColor: "#2B211A",
  filterLocationHoverColor: "#e1e1e1",
  tileShadowColor: "#2B211A",
  tileColor: "#E2E738",
  tileHoverColor: "#2B211A",
  tileTextColor: "#2B211A",
  tileHoverTextColor: "#E2E738",
  lineColor: "#768104",
  circleColor: "#768104",
  typeListColor: "#fff",
  typeListTextColor: "#2B211A",
  typeListHoverColor: "#E2E738",
  typeListHoverTextColor: "#2B211A",
  countriesHoverColor: "#000",
  infoWindowColor: "#fff",
  infoWindowButtonRightColor: "#2B211A",
  infoWindowButtonRightTextColor: "#fff",
  infoWindowButtonLeftColor: "#E2E738",
  infoWindowButtonLeftTextColor: "#2B211A",
  infoWindowTextColor: "#768104",
  infoWindowCloseColor: "#E2E738",
  infoWindowCloseTextColor: "#2B211A",
  filterBreadCrumbColor: "#E2E738",
  filterBreadCrumbTextColor: "#2B211A",
  mapColor: "#eef0a8",
  clusterColor: "##f1f500",
  ocean: "#c9cd98",
  marker: "#fa141c",
  mapTheme: "mapbox://styles/verb-mapbox/ck2fe4xhm01ow1co1wy8hpf1t"
};

export const karakoy = {
  mapBackground: "#e8e8e8",
  buttonColor: "#2B211A",
  buttonTextColor: "#fff",
  filterColor: "#fff",
  filterTextColor: "#2B211A",
  filterCheckMarkColor: "#2B211A",
  filterLocationColor: "#e5e5e5",
  filterLocationTextColor: "#2B211A",
  filterLocationHoverColor: "#e1e1e1",
  tileShadowColor: "#2B211A",
  tileColor: "#2B211A",
  tileHoverColor: "#eee",
  tileTextColor: "#fff",
  tileHoverTextColor: "#2B211A",
  lineColor: "#2B211A",
  circleColor: "#2B211A",
  typeListColor: "#fff",
  typeListTextColor: "#2B211A",
  typeListHoverColor: "#6F7271",
  typeListHoverTextColor: "#FFF",
  countriesHoverColor: "#2B211A",
  infoWindowColor: "#fff",
  infoWindowButtonRightColor: "#6F7271",
  infoWindowButtonRightTextColor: "#fff",
  infoWindowButtonLeftColor: "#C4C4BC",
  infoWindowButtonLeftTextColor: "#fff",
  infoWindowTextColor: "#C4C4BC",
  infoWindowCloseColor: "#C4C4BC",
  infoWindowCloseTextColor: "#fff",
  filterBreadCrumbColor: "#FFF",
  filterBreadCrumbTextColor: "#2B211A",
  mapColor: "#6F7271",
  clusterColor: "#ff9924",
  mapTheme: "mapbox://styles/verb-mapbox/ck2fe7yax01sc1co73idytb63"
};

export const umami = {
  mapBackground: "#e8e8e8",
  buttonColor: "#E46D27",
  buttonTextColor: "#fff",
  filterColor: "#fff",
  filterTextColor: "#2C211A",
  filterCheckMarkColor: "#E46D27",
  filterLocationColor: "#D8D8D8",
  filterLocationTextColor: "#2C211A",
  filterLocationHoverColor: "#e1e1e1",
  tileShadowColor: "#2C211A",
  tileColor: "#E46D27",
  tileHoverColor: "#2C211A",
  tileTextColor: "#fff",
  tileHoverTextColor: "#fff",
  lineColor: "#ec9665",
  circleColor: "#ec9665",
  typeListColor: "#fff",
  typeListTextColor: "#2C211A",
  typeListHoverColor: "#E46D27",
  typeListHoverTextColor: "#fff",
  countriesHoverColor: "#E46D27",
  infoWindowColor: "#fff",
  infoWindowButtonRightColor: "#2C211A",
  infoWindowButtonRightTextColor: "#fff",
  infoWindowButtonLeftColor: "#E46D27",
  infoWindowButtonLeftTextColor: "#fff",
  infoWindowTextColor: "#E46D27",
  infoWindowCloseColor: "#E46D27",
  infoWindowCloseTextColor: "#fff",
  filterBreadCrumbColor: "#E46D27",
  filterBreadCrumbTextColor: "#fff",
  mapColor: "#3b1b07",
  clusterColor: "#ff9924",
  ocean: "#f6cfb6",
  marker: "#ff7d1a",
  mapTheme: "mapbox://styles/verb-mapbox/ck2featr101un1co0i5u8x7h3"
};

export const filia = {
  mapBackground: "#e8e8e8",
  buttonColor: "#395642",
  buttonTextColor: "#fff",
  filterColor: "#fff",
  filterTextColor: "#2C211A",
  filterCheckMarkColor: "#2A4231",
  filterLocationColor: "#D8D8D8",
  filterLocationTextColor: "#2C211A",
  filterLocationHoverColor: "#e1e1e1",
  tileShadowColor: "#2C211A",
  tileColor: "#395642",
  tileHoverColor: "#B8D9C8",
  tileTextColor: "#fff",
  tileHoverTextColor: "#1f2e23",
  lineColor: "#2A4231",
  circleColor: "#2A4231",
  typeListColor: "#F1EADA",
  typeListTextColor: "#2A4231",
  typeListHoverColor: "#E7E0D8",
  typeListHoverTextColor: "#2A4231",
  countriesHoverColor: "#395642",
  infoWindowColor: "#fff",
  infoWindowButtonRightColor: "#B8D9C8",
  infoWindowButtonRightTextColor: "#2C211A",
  infoWindowButtonLeftColor: "#395642",
  infoWindowButtonLeftTextColor: "#fff",
  infoWindowTextColor: "#395642",
  infoWindowCloseColor: "#395642",
  infoWindowCloseTextColor: "#fff",
  filterBreadCrumbColor: "#F1EADA",
  filterBreadCrumbTextColor: "#2A4231",
  mapColor: "#dfcfaa",
  clusterColor: "#2A4231",
  ocean: "#e9dec4",
  marker: "#ff9924",
  mapTheme: "mapbox://styles/verb-mapbox/ck2feg9w5020s1bpe84v7cqsr"
};

export const cleo = {
  mapBackground: "#e8e8e8",
  buttonColor: "#9E7A54",
  buttonTextColor: "#fff",
  filterColor: "#fff",
  filterTextColor: "#2D373F",
  filterCheckMarkColor: "#9E7A54",
  filterLocationColor: "#D8D8D8",
  filterLocationTextColor: "#2B211A",
  filterLocationHoverColor: "#e1e1e1",
  tileShadowColor: "#2B211A",
  tileColor: "#9E7A54",
  tileHoverColor: "#2D373F",
  tileTextColor: "#fff",
  tileHoverTextColor: "#F1E6D5",
  lineColor: "#ba9d7d",
  circleColor: "#ba9d7d",
  typeListColor: "#fff",
  typeListTextColor: "#2B211A",
  typeListHoverColor: "#9E7A54",
  typeListHoverTextColor: "#fff",
  countriesHoverColor: "#9E7A54",
  infoWindowColor: "#fff",
  infoWindowButtonRightColor: "#F1E6D5",
  infoWindowButtonRightTextColor: "#2D373F",
  infoWindowButtonLeftColor: "#9E7A54",
  infoWindowButtonLeftTextColor: "#fff",
  infoWindowTextColor: "#2D373F",
  infoWindowCloseColor: "#9E7A54",
  infoWindowCloseTextColor: "#fff",
  filterBreadCrumbColor: "#9E7A54",
  filterBreadCrumbTextColor: "#fff",
  mapColor: "#b8a693",
  clusterColor: "#ff9924",
  mapTheme: "mapbox://styles/verb-mapbox/ck2fel9x7023y1clglso3mkzj"
};

export const bazzarMar = {
  mapBackground: "#e8e8e8",
  buttonColor: "#3377B4",
  buttonTextColor: "#fff",
  filterColor: "#fff",
  filterTextColor: "#000",
  filterCheckMarkColor: "#3377B4",
  filterLocationColor: "#D8D8D8",
  filterLocationTextColor: "#000000",
  filterLocationHoverColor: "#738ea5",
  tileShadowColor: "#000000",
  tileColor: "#9E7A54",
  tileHoverColor: "#BC2B38",
  tileTextColor: "#fff",
  tileHoverTextColor: "#fff",
  lineColor: "#ba9d7d",
  circleColor: "#ba9d7d",
  typeListColor: "#fff",
  typeListTextColor: "#2B211A",
  typeListHoverColor: "#3377B4",
  typeListHoverTextColor: "#fff",
  countriesHoverColor: "#9E7A54",
  infoWindowColor: "#fff",
  infoWindowButtonRightColor: "#BC2B38",
  infoWindowButtonRightTextColor: "#fff",
  infoWindowButtonLeftColor: "#3377B4",
  infoWindowButtonLeftTextColor: "#fff",
  infoWindowTextColor: "#000000",
  infoWindowCloseColor: "#9E7A54",
  infoWindowCloseTextColor: "#fff",
  filterBreadCrumbColor: "#3377B4",
  filterBreadCrumbTextColor: "#fff",
  mapColor: "#738ea5",
  clusterColor: "#fa141c",
  marker: "#fa141c",
  mapTheme: "mapbox://styles/verb-mapbox/ck2ferto902bz1clclmedqp6v"
};

export const katsuya = {
  mapBackground: "#e8e8e8",
  buttonColor: "#EA2B2E",
  buttonTextColor: "#fff",
  filterColor: "#fff",
  filterTextColor: "#000000",
  filterCheckMarkColor: "#E77B2E",
  filterLocationColor: "#D8D8D8",
  filterLocationTextColor: "#000000",
  filterLocationHoverColor: "#f1b488",
  tileShadowColor: "#000000",
  tileColor: "#000000",
  tileHoverColor: "#EA2B2E",
  tileTextColor: "#fff",
  tileHoverTextColor: "#fff",
  lineColor: "#B01D33",
  circleColor: "#B01D33",
  typeListColor: "#fff",
  typeListTextColor: "#2B211A",
  typeListHoverColor: "#E77B2E",
  typeListHoverTextColor: "#fff",
  countriesHoverColor: "#B01D33",
  infoWindowColor: "#fff",
  infoWindowButtonRightColor: "#EA2B2E",
  infoWindowButtonRightTextColor: "#fff",
  infoWindowButtonLeftColor: "#E77B2E",
  infoWindowButtonLeftTextColor: "#fff",
  infoWindowTextColor: "#000000",
  infoWindowCloseColor: "#B01D33",
  infoWindowCloseTextColor: "#fff",
  filterBreadCrumbColor: "#E77B2E",
  filterBreadCrumbTextColor: "#fff",
  mapColor: "#a47071",
  clusterColor: "#fa141c",
  katsuya: "#fa141c",
  mapTheme: "mapbox://styles/verb-mapbox/ck2fewm2h02f91cjyfhjys8xd"
};

export const hyde = {
  mapBackground: "#e8e8e8",
  buttonColor: "#AB732B",
  buttonTextColor: "#fff",
  filterColor: "#fff",
  filterTextColor: "#000",
  filterCheckMarkColor: "#AB732B",
  filterLocationColor: "#D8D8D8",
  filterLocationTextColor: "#000000",
  filterLocationHoverColor: "#c6bbae",
  tileShadowColor: "#000000",
  tileColor: "#9E7A54",
  tileHoverColor: "#000000",
  tileTextColor: "#fff",
  tileHoverTextColor: "#fff",
  lineColor: "#ba9d7d",
  circleColor: "#ba9d7d",
  typeListColor: "#fff",
  typeListTextColor: "#000000",
  typeListHoverColor: "#9E7A54",
  typeListHoverTextColor: "#fff",
  countriesHoverColor: "#9E7A54",
  infoWindowColor: "#fff",
  infoWindowButtonRightColor: "#DDDDDD",
  infoWindowButtonRightTextColor: "#000000",
  infoWindowButtonLeftColor: "#AB732B",
  infoWindowButtonLeftTextColor: "#fff",
  infoWindowTextColor: "#000000",
  infoWindowCloseColor: "#9E7A54",
  infoWindowCloseTextColor: "#fff",
  filterBreadCrumbColor: "#AB732B",
  filterBreadCrumbTextColor: "#fff",
  clusterColor: "#ff9924",
  marker: "#ff9924",
  mapTheme: "mapbox://styles/verb-mapbox/ck2ff81o202ps1cjykmrbpr7o"
};


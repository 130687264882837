import React from "react";
import { setCluster, setLocation } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import Cluster from "../../components/Cluster/Cluster";

import getClusters from "../../utils/getClusters";
import createLocationViewport from "../../utils/createLocationViewport";
import createClusterViewport from "../../utils/createClusterViewport";

export default function ClustersContainer({ tile, project }) {
  const clusters = getClusters([tile]);
  const dispatch = useDispatch();
  const viewport = useSelector(state => state.viewport);

  const handleClusterClick = cluster => {
    const types = _.get(cluster, "types", {});
    const locations =
      Object.values(types).length > 0 &&
      Object.entries(types).reduce((array, data) => {
        const clusterLocations = data[1];
        clusterLocations.forEach(location => {
          array.push(location);
        });
        return array;
      }, []);

    if (locations && locations.length === 1) {
      dispatch(
        setLocation({
          location: locations[0],
          viewport: createLocationViewport(locations[0])
        })
      );
    } else {
      dispatch(
        setCluster({
          cluster: cluster.id,
          viewport: createClusterViewport(cluster, viewport)
        })
      );
    }
  };

  const getPosition = function(cluster) {
    const [x, y] =
      cluster.coordinates && cluster.coordinates.lng && cluster.coordinates.lat
        ? project([cluster.coordinates.lng, cluster.coordinates.lat])
        : [0, 0];
    return { x, y };
  };

  const getTotal = function(cluster) {
    let total = 0;
    Object.values(_.get(cluster, "types", {})).forEach(function(type) {
      if (type && type.length) {
        total += type.length;
      }
    }, 0);
    return total;
  };

  if (clusters.length > 0) {
    return (
      <>
        {clusters.map((cluster, j) => (
          <Cluster
            key={`${cluster.name}${j}`}
            cluster={cluster}
            position={getPosition(cluster)}
            tile={tile}
            total={getTotal(cluster)}
            onClusterClick={handleClusterClick}
          />
        ))}
      </>
    );
  }

  return null;
}

import React, { forwardRef } from "react";
import { useSelector } from "react-redux";
import * as styles from "./styles";
import { Marker2Icon } from "../../utils/icons";

function Marker({ location, type, onClick }, ref) {
  const selectedMarker = useSelector(state => state.selectedMarker);
  const markerClass = selectedMarker === location.id ? "jump" : "";
  return (
    <styles.Marker ref={ref} onClick={onClick} className={markerClass}>
      {location.marker ? (
        <img src={location.marker} alt={`${location.name} marker`} />
      ) : (
        <Marker2Icon />
      )}
    </styles.Marker>
  );
}

export default forwardRef(Marker);

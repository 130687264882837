import styled, { keyframes } from "styled-components";

const fade = keyframes`
from{
  opacity: 0;
  transform: translateX(-30px)
} to{
  opacity: 1;
  transform: translateX(0px)
}`;

export const Wrapper = styled.div`
  width: 238px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 104;
  animation: ${fade} 0.7s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background-color: ${props => props.theme.colors.infoWindowColor};

  @media ${props => props.theme.devices.mobileL} {
    width: 300px;
  }

  @media ${props => props.theme.devices.laptopL} {
    width: 400px;
  }
`;

export const Image = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 12px;

  @media ${props => props.theme.devices.laptopS} {
    display: block;
    padding: 0;
  }

  img {
    width: 40%;
    height: 100%;
    max-height: 170px;
    object-fit: cover;
    display: none;
    min-height: 120px;

    @media ${props => props.theme.devices.tablet} {
      display: block;
    }

    @media ${props => props.theme.devices.laptopS} {
      width: 100%;
    }
    @media ${props => props.theme.devices.laptopM} {
      max-height: 200px;
    }
  }
`;
export const Text = styled.div`
      flex: 2 0 100px;
      padding: 12px 48px 12px 21px;
      background-color: ${props => props.theme.colors.infoWindowColor}; 

      @media ${props => props.theme.devices.laptopS} {
        flex: 2 0 100px;
        padding: 1em;
      }

    span {
      text-transform: uppercase;
      color: ${props => props.theme.colors.infoWindowTextColor};
      font-size: 14px;
      display: none;
      align-items: center;

      @media ${props => props.theme.devices.mobileL} {
        display: flex;
      }
    }

    span svg {
      width: 22px;
      max-height: 22px;
      fill: ${props => props.theme.colors.infoWindowTextColor};
      margin-right: 10px;
    }
    

    h1 {
      color: ${props => props.theme.colors.infoWindowTextColor};
      margin: 0 0 5px 0;
      padding: 0;
      font-size: 14px
      font-weight: 600;

      @media ${props => props.theme.devices.laptopS} {
        margin: 10px 0 22px 0;
        max-width: 220px;
      }
    }

    p {
      color: ${props => props.theme.colors.infoWindowTextColor};
      font-size: 12px;
      margin: 0;
      padding: 0;
      font-weight: 100;
    }
`;

export const Navigation = styled.div`
  display: flex;
  width: 100%;

  @media ${props => props.theme.devices.laptopS} {
    width: auto;
  }

  a {
    margin: 0;
    padding: 8px 9px;
    text-decoration: none;
    white-space: nowrap;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.3s;
    flex: 1;
    text-align: center;
    font-size: 13px;
    max-width: 40px;
    height: 50%;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &:nth-of-type(2) {
      top: 50%;

      @media ${props => props.theme.devices.laptopS} {
        top: 0px;
      }
    }

    @media ${props => props.theme.devices.laptopS} {
      font-size: 18px;
      position: relative;
      max-width: 100%;
      height: auto;
    }

    svg {
      position: relative;
      display: block;
      width: 20px;
      fill: #fff;
    }
  }

  a:hover {
    opacity: 0.8;
  }

  a:first-of-type {
    color: ${props => props.theme.colors.infoWindowButtonLeftTextColor};
    background-color: ${props => props.theme.colors.infoWindowButtonLeftColor};
  }

  a:nth-of-type(2) {
    color: ${props => props.theme.colors.infoWindowButtonRightTextColor};
    background-color: ${props => props.theme.colors.infoWindowButtonRightColor};
  }
`;

export const Close = styled.div`
  position: absolute;
  display: none;
  top: 0px;
  right: 0px;
  width: 50px;
  height: 20px;
  z-index: 0;
  background: ${props => props.theme.colors.infoWindowCloseColor};
  cursor: pointer;
  padding: 5px;
  transition: all 0.4s;
  opacity: 0.8;

  &:hover {
    color: ${props => props.theme.colors.infoWindowCloseColor};
    background: ${props => props.theme.colors.infoWindowCloseTextColor};
  }

  @media ${props => props.theme.devices.mobileL} {
    display: block;
  }
`;

export const Description = styled.p`
  display: none;

  &:after {
    content: "";
    display: block;
    width: 40px;
    height: 1px;

    margin-top: 12px;
    background: ${props => props.theme.colors.infoWindowTextColor};
    opacity: 0.2;
  }

  @media ${props => props.theme.devices.laptopS} {
    display: block;
    padding: 0 0 10px 0 !important;
  }

  span {
    display: inline;
    text-transform: capitalize;
    font-size: 12px;
  }
`;

export const Logo = styled.div`
  display: none;
  max-width: 115px;
  height: 50px;
  position: absolute;
  right: 36px;
  top: 220px;

  @media ${props => props.theme.devices.laptopS} {
    display: block;
  }

  svg {
    width: 100%;
    max-height: 100%;
  }
`;

export const Phone = styled.div`
  display: flex;
  align-items: center;

  & > svg {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }

  a {
    color: ${props => props.theme.colors.infoWindowTextColor};
    font-size: 12px;
    text-decoration: none;
  }
`;

export const Address = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 7px;

  & > svg {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }

  a {
    display: block;
    text-decoration: none;
    color: ${props => props.theme.colors.infoWindowTextColor};
    font-size: 12px;
  }
`;

import hotelPlaceholder from "../assets/hotelPlaceholder.jpg";
import nightlifePlaceholder from "../assets/nightlifePlaceholder.jpg";
import restaurantPlaceholder from "../assets/restaurantPlaceholder.jpg";
import loader from "../assets/brand-loader.gif";

export default {
  hotelPlaceholder,
  nightlifePlaceholder,
  restaurantPlaceholder,
  loader
};

import React from "react";
import * as styles from "./styles";
import LocationAddress from "./LocationAddress";

function LocationCard({
  id,
  name,
  image,
  address,
  phone,
  onClick,
  onMouseEnter,
  onMouseLeave,
  imagePlaceholder
}) {
  return (
    <styles.Location onClick={onClick} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <styles.LocationImageWrapper>
        <styles.LocationImage>
          <img src={image ? image : imagePlaceholder} alt={`${name}`} />
        </styles.LocationImage>
      </styles.LocationImageWrapper>
      <styles.LocationInfo>
        <h2>{name}</h2>
        <LocationAddress address={address} />
        <styles.LocationAddressPhone>{phone}</styles.LocationAddressPhone>
      </styles.LocationInfo>
    </styles.Location>
  );
}

export default LocationCard;

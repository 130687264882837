export default function getLocationTypeCounts(types) {
  const locationTypeCounts = {
    total: 0
  };

  if (types) {
    Object.entries(types).forEach(function(data) {
      const type = data[0];
      const locations = data[1];
      locationTypeCounts.total += locations.length;

      if (locationTypeCounts[type] === undefined) {
        locationTypeCounts[type] = 0;
      }

      locationTypeCounts[type] += locations.length;
    });
  }

  return locationTypeCounts;
}

import styled from "styled-components";

export const FilterCheckBox = styled.div`
  &:first-child {
    margin-top: 0.5em;
  }
  margin-left: 0.5em;
  padding: 3px 0;
  display: inline-block;

  svg {
    width: 20px;
    height: 20px;
    max-width: 20px;
    fill: ${props => props.theme.colors.filterTextColor};
    margin-left: 30px;
    margin-right: 8px;
  }

  input[type="checkbox"] {
    opacity: 0;
    display: none;
  }

  input[type="checkbox"] + label:after {
    content: none;
  }

  input[type="checkbox"]:checked + label:after {
    content: "";
    border-style: red;
  }

  label {
    position: relative;
    text-transform: capitalize;
    font-weight: normal;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:before,
    &:after {
      position: absolute;
    }

    &:before {
      content: "";
      display: inline-block;
      height: 20px;
      width: 20px;
      border: 1px solid;
      top: -3px;
    }

    &:after {
      content: "";
      display: inline-block;
      height: 12px;
      width: 16px;
      border-left: 3px solid ${props => props.theme.colors.filterCheckMarkColor};
      border-bottom: 2px solid
        ${props => props.theme.colors.filterCheckMarkColor};
      transform: rotate(-45deg);
      left: 4px;
      top: -4px;
    }
  }
`;

import _ from "lodash";

/**
 * Get all the clusters from the given tiles
 */
export default function getClusters(tiles) {
  const clusters = [];

  if (tiles && !Array.isArray(tiles)) {
    tiles = [tiles];
  }

  if (tiles && tiles.length > 0) {
    tiles.forEach(function(tile) {
      _.get(tile, "clusters", []).forEach(function(cluster) {
        clusters.push(cluster);
      });
    });
  }
  return clusters;
}

import theme from "../themes/index.variables";
import { FlyToInterpolator } from "react-map-gl";
import { easeCircleInOut } from "d3-ease";
import mapboxgl from "mapbox-gl";
import getCoordsFromBounds from "./getCoordsFromBounds";

export default function createRegionViewport (region, viewport) {
  const bounds = new mapboxgl.LngLatBounds();
  Object.values(region.clusters).forEach(
    ({ coordinates: { lat = null, lng = null } }) => {
      lat && lng && bounds.extend([lng, lat]);
    }
  );

  const padding = viewport.width > theme.devices.laptopM
            ? { top: 250, bottom: 250, left: 350, right: 250 }
            : { top: 80, bottom: 80, left: 190, right: 80 }

  const { longitude, latitude, zoom } = getCoordsFromBounds({viewport, bounds, padding});

  return {
    longitude: longitude,
    latitude: latitude,
    zoom: Math.min(zoom - 1, 10),
    transitionDuration: 1900,
    transitionInterpolator: new FlyToInterpolator(),
    transitionEasing: easeCircleInOut,
  };
}

import _ from "lodash";

export default function getLocationByName(tiles, name) {
  name = name.toLowerCase();
  let search = null;
  if (tiles && name) {
    Object.values(tiles).find(tile => {
      return Object.values(tile.clusters).find(cluster => {
        const types = _.get(cluster, 'types', [{}]);
        return Object.entries(types).find(data => {
          const locations = data[1];
          if (locations.length > 0) {
            return locations.find(location => {
              if (_.get(location, 'name', "").toLowerCase() === name) {
                return search = location;
              }
              return false;
            });
          }
          return false;
        });
      });
    });
  }
  return search;
}

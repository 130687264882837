import styled from "styled-components";

export const Container = styled.div`
  z-index: 1;
  position: absolute;
  top: 7px;
  left: 0;
  right: 0;
  width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &.show {
    z-index: 3;
  }

  @media ${props => props.theme.devices.laptopS} {
    top: 34px;
    transform: translateX(-91%);
    height: 50px;
  }

  & > svg {
    position: absolute;
    width: 27px;
    max-height: 18px;
    right: 10px;
    top: 10px;
    fill: #333;
    cursor: pointer;
    transition: all 0.5s ease;

    &:hover {
      transform: scale(1.1, 1.1);
    }
  }

  @media ${props => props.theme.devices.laptopS} {
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    display: block;
    width: 450px;
    box-shadow: 2px 2px 5px #444;
    transition: transform 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    background: #fff;

    &.show {
      transform: translateX(-37%);
    }

    &.showMore {
      transform: translateX(-15%);
    }
  }
`;

export const Open = styled.div`
  display: none;
  position: absolute;
  width: ${props => (props.isOpened ? "17px" : "27px")};
  right: 10px;
  cursor: pointer;
  transition: all 0.5s ease;
  top: ${props => (props.isOpened ? "17px" : "10px")};
  &:hover {
    transform: scale(1.1, 1.1);
  }
  & > svg {
    fill: #333;
    max-height: ${props => (props.isOpened ? "17px" : "27px")};
  }
  @media ${props => props.theme.devices.laptopS} {
    display: block;
  }
`;

export const Filter = styled.div`
  position: absolute;
  left: ${props => (props.isFilterOpened ? "156px" : "173px")};
  top: 12px;
  display: none;

  @media ${props => props.theme.devices.tablet} {
    display: block;
  }

  &:before {
    color: #999;
    display: block;
    position: absolute;
    content: "Filter by";
    width: 50px;
    height: 30px;
    font-size: 10px;
    text-transform: uppercase;
    top: -8px;
    left: -76px;
    white-space: nowrap;
  }

  select {
    background: transparent;
    font-size: 15px;
    position: absolute;
    top: 4px;
    border: 0;
    margin-left: -78px;
    color: #444;
    font-weight: 100;

    width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: "";

    &::-ms-expand {
      display: none;
    }

    option {
      text-transform: capitalize;
    }
  }

  svg {
    width: 25px;
    max-height: 25px;

    fill: #555;
    cursor: pointer;
    transition: all 0.6s ease;

    &:hover {
      transform: scale(1.1, 1.1);
      fill: #000;
    }
  }
`;

export const ClearButton = styled.button`
  border: 0;
  background: transparent;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
  opacity: 0.6;

  svg {
    height: 20px;
  }

  @media ${props => props.theme.devices.laptopS} {
    display: none;
  }
`;

export const Input = styled.div`
  width: 100%;
  max-width: 450px;
  padding: 0 0.5em;

  @media ${props => props.theme.devices.tablet} {
    width: auto;
    max-width: 100%;
    padding: 0;
  }

  > div {
    box-shadow: 2px 2px 5px #444;
    display: flex;
    background: #fff;
    @media ${props => props.theme.devices.tablet} {
      border-top-right-radius: 25px;
      border-bottom-right-radius: 25px;
    }
  }

  input {
    height: 50px;
    width: 100%;
    flex: 1;
    font-size: 15px;
    border: none;
    color: #444;
    outline: none;
    padding: 0;

    @media ${props => props.theme.devices.tablet} {
      margin-left: 215px;
      margin-right: 40px;
      width: 196px;
      padding: 0;
      box-shadow: none;
    }
  }
`;

export const ResultsDropdown = styled.div`
  cursor: pointer;
  max-width: 23px;
  max-height: 23px;
  position: absolute;
  top: 19px;
  right: 38px;
  display: none;

  @media ${props => props.theme.devices.tablet} {
    display: block;
  }

  svg {
    width: 100%;
    max-height: 16px;
    transition: all 1s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  &.show {
    svg {
      transform: rotate(180deg);
    }
  }
`;

export const Results = styled.div`
  transition: all 1s ease;
  background: #fff;
  box-shadow: 2px 2px 5px #444;
  margin-left: 34px;
  width: 100%;
  margin-top: 5px;
  overflow: auto;
  height: 100%;
  flex: 1;
  margin: 0.5em 1em;
  max-width: 450px;
  height: 600px;

  @media ${props => props.theme.devices.tablet} {
    max-height: 270px;
    margin-left: 172px;
    max-width: 280px;
  }

  @media ${props => props.theme.devices.laptopS} {
    max-height: 370px;
  }

  @media ${props => props.theme.devices.laptopM} {
    max-height: 500px;
  }

  @media ${props => props.theme.devices.desktop} {
    max-height: 600px;
  }

  & > p {
    margin: 7px;

    @media ${props => props.theme.devices.tablet} {
      margin: 18px;
    }
  }
`;

export const Tabs = styled.div`
  display: flex;
  flex-direction: row;
  width: 100;
  margin-top: 0.5em;
`;

export const Tab = styled.button`
  background: #fff;
  border: 0;
  padding: 1em;
  flex: 1;
  outline: 0;
  border-bottom: 4px solid transparent;
  transition: 0.4s ease-out;
  color: #3f3f3b;

  &.active {
    color: ${props => props.theme.colors.searchActiveTabColor};
    border-bottom: 4px solid ${props => props.theme.colors.searchActiveTabColor};
  }

  h1 {
    margin: 0;
  }
  label {
    font-size: 12px;
    font-weight: bold;
  }
`;

export const Type = styled.div`
  & > h1 {
    text-transform: capitalize;
    font-size: 15px;
    margin: 0;
    background: #e8e8e7;
    padding: 10px 20px;
    display: none;
  }

  & > div {
    &:after {
      display: block;
      content: "";
      border-bottom: 1px dashed #e7e7e7;
    }
  }
  @media ${props => props.theme.devices.laptopS} {
    & > h1 {
      display: block;
    }
  }
`;

export const Total = styled.p`
  text-transform: uppercase;
  font-weight: bolder;
  letter-spacing: 1px;
  font-size: 10px;
  text-align: end;
  margin: 10px;
  display: none;
  @media ${props => props.theme.devices.laptopS} {
    display: block;
  }
`;

export const Types = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: auto;

  & > p {
    font-size: 12px;
    text-align: center;

    &:last-of-type {
      font-size: 11px;
      text-transform: uppercase;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  @media ${props => props.theme.devices.laptopS} {
    flex-direction: column;
    overflow: initial;
  }
`;

export const MobileIcon = styled.div`
  &.back {
    .backIcon {
      display: block;
    }
    .searchIcon {
      display: none;
    }
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1em;
    height: 100%;
    background: transparent;
    border: 0;

    svg {
      width: 20px;
      path {
        fill: #707070;
      }
    }
  }

  .backIcon {
    display: none;
  }

  @media ${props => props.theme.devices.laptopS} {
    display: none;
  }
`;

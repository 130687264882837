import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as styles from "./styles";
import {
  ArrowRight,
  MapIcon,
  HotelIcon,
  BarIcon,
  RestaurantIcon,
  BackIcon,
  Maximize,
  Minimize,
  DefaultIcon
} from "../../utils/icons";

import { setFilters, resetMap, setLocation, filterToRegion } from "../../redux/actions";

import { size } from "../../themes/index.variables";
import getLocationTypeCounts from "../../utils/getLocationTypeCounts";
import getLocationsByType from "../../utils/getLocationsByType";
import getTile from "../../utils/getTile";
import getClusters from "../../utils/getClusters";
import getTypes from "../../utils/getTypes";
import getLocations from "../../utils/getLocations";
import filterTile from "../../utils/filterTile";
import createSearchViewport from "../../utils/createSearchViewport";
import createBaseViewport from "../../utils/createBaseViewport";
import createLocationViewport from "../../utils/createLocationViewport";

function InfoSlide() {
  const [isMinimized, setMinimized] = useState(false);
  const [isDisplayingContent, setDisplayingContent] = useState(false);
  const tiles = useSelector(state => state.tiles);
  const selectedTile = getTile(
    tiles,
    useSelector(state => state.selectedTile)
  );
  const filters = useSelector(state => state.filters);
  const viewport = useSelector(state => state.viewport);
  const labels = useSelector(state => state.labels);

  const dispatch = useDispatch();

  const clusters = getClusters(selectedTile);
  const locationsByType = getLocationsByType(clusters);
  const totalLocations = getLocationTypeCounts(locationsByType);
  const availableTypes = getTypes(getClusters(selectedTile));
  const types = filters.types;

  useEffect(() => {
    if (!isMinimized) {
      setTimeout(() => {
        setMinimized(isDisplayingContent);
      }, 450);
    } else {
      setMinimized(isDisplayingContent);
    }
  }, [isDisplayingContent, isMinimized]);

  const handleFilterChanged = (type, e) => {
    e.stopPropagation();
    const newTypes = [...types];
    const index = newTypes.indexOf(type);
    if (index === -1) {
      newTypes.push(type);
    } else {
      newTypes.splice(index, 1);
    }
    dispatch(
      setFilters({
        types: newTypes
      })
    );
  };

  const handleCloseInfoSlide = () => {
    dispatch(resetMap(createBaseViewport()));
  };

  const handleMinimizeInfoSlide = () => {
    setDisplayingContent(!isDisplayingContent);
  };

  const handleTypeClick = type => {
    const newFilters = type ? [type] : availableTypes;
    const filteredTile = filterTile(selectedTile, { types: newFilters });
    const locations = getLocations(getClusters(filteredTile));

    if (locations.length === 1) {
      dispatch(
        setLocation({
          location: locations[0],
          filters: newFilters,
          viewport: { ...createLocationViewport(locations[0]) }
        })
      );
    } else {
      dispatch(
        filterToRegion({
          tile: selectedTile.id,
          filters: {
            types: newFilters
          },
          viewport: { ...createSearchViewport(filteredTile.clusters, viewport) }
        })
      );
    }
  };

  const renderDescription = description => {
    const showMore = e => {
      e.target.parentElement.innerHTML = description;
    };
    if (!description) return null;
    const smallText = description
      .split(" ")
      .slice(0, 15)
      .join(" ");

    if (window.innerWidth < size.laptopS) {
      return (
        <p>
          {smallText}...<span onClick={showMore}>read more!</span>
        </p>
      );
    } else {
      return <p>{description}</p>;
    }
  };

  const icons = {
    hotel: <HotelIcon />,
    nightclub: <BarIcon />,
    restaurant: <RestaurantIcon />
  };

  const containerClass = `display ${isDisplayingContent ? "show" : ""} ${
    isMinimized ? "minimized" : ""
  }
  `;

  return (
    <>
      <styles.Container className={containerClass}>
        <styles.CloseContainer onClick={handleCloseInfoSlide}>
          <BackIcon />
        </styles.CloseContainer>
        <styles.MinimizeContainer onClick={handleMinimizeInfoSlide}>
          {isMinimized ? <Maximize /> : <Minimize />}
        </styles.MinimizeContainer>
        <styles.MapContainer>
          <MapIcon />
        </styles.MapContainer>
        {selectedTile && selectedTile.name && <h1>{selectedTile.name}</h1>}
        {selectedTile && renderDescription(selectedTile.description)}
        {totalLocations && (
          <>
            <styles.Locations>
              {totalLocations.total} {labels.locations} - {labels.filterByType}:
            </styles.Locations>

            <styles.TypeContainer>
              {availableTypes.length > 0 &&
                availableTypes.map(type => {
                  return (
                    totalLocations[type] !== 0 && (
                      <styles.Type key={type} highlight={types.includes(type)}>
                        <>
                          {type && icons[type.toLowerCase()] ? (
                            icons[type.toLowerCase()]
                          ) : (
                            <DefaultIcon />
                          )}
                        </>
                        <div onClick={() => handleTypeClick(type)} data-type={type}>
                          {totalLocations[type]}
                          <span>{labels[type]}</span>
                        </div>
                        <input
                          onChange={e => handleFilterChanged(type, e)}
                          type="checkbox"
                          name={type}
                          id={type}
                          checked={types.includes(type)}
                        />
                        <label htmlFor={type}></label>
                      </styles.Type>
                    )
                  );
                })}
              <styles.Type>
                <styles.Next onClick={() => handleTypeClick()}>
                  <p>{labels.seeAllLocations}</p>
                  <ArrowRight />
                </styles.Next>
              </styles.Type>
            </styles.TypeContainer>
          </>
        )}
      </styles.Container>
    </>
  );
}

export default React.memo(InfoSlide);

import React, { useState } from "react";
import { useSelector } from "react-redux";
import * as styles from "./styles";
import { FilterIcon, CloseIcon } from "../../utils/icons";

import Locations from "./Locations/Locations";
import TypeOptions from "./TypeOptions/TypeOptions";

import getTile from "../../utils/getTile";
import getClusters from "../../utils/getClusters";
import getCluster from "../../utils/getCluster";
import getTypes from "../../utils/getTypes";

function Filters() {
  const tiles = useSelector(state => state.tiles);
  const selectedTile = getTile(
    tiles,
    useSelector(state => state.selectedTile)
  );
  const availableTypes = getTypes(getClusters(selectedTile));
  const filters = useSelector(state => state.filters);
  const labels = useSelector(state => state.labels);
  const selectedCluster = getCluster(
    selectedTile,
    useSelector(state => state.selectedCluster)
  );

  const types = filters.types;
  const clusters = selectedCluster ? [selectedCluster] : getClusters(selectedTile);

  const [isFilterOpened, setFilterOpened] = useState(true);

  const handleToggleFilter = () => {
    setFilterOpened(!isFilterOpened);
  };

  const handleCloseFilterClick = () => {
    setFilterOpened(false);
  };

  return (
    availableTypes.length > 0 && (
      <styles.Container>
        <styles.ButtonCircle onClick={handleToggleFilter} isFilterOpened={isFilterOpened}>
          <div>
            <FilterIcon />
          </div>
        </styles.ButtonCircle>

        <styles.Wrapper className={`${isFilterOpened ? "show" : ""}`}>
          <styles.Close onClick={handleCloseFilterClick}>
            <CloseIcon />
          </styles.Close>
          {clusters && clusters.length === 1 && clusters[0].name && <h1>{clusters[0].name}</h1>}
          {clusters && clusters.length === 1 && clusters[0].description && (
            <p>{clusters[0].description}</p>
          )}
          <h2>{labels.filterByTypes}</h2>
          <TypeOptions />
          {types && types.length > 0 ? (
            <h2>{labels.locations}</h2>
          ) : (
            !selectedTile && <styles.Message>{labels.noFilterTypes}</styles.Message>
          )}

          <>
            {selectedTile && (
              <styles.Locations>
                <Locations clusters={clusters} globalSelections={types} tile={selectedTile} />
              </styles.Locations>
            )}
            {types && types.length === 0 && <styles.Message>{labels.noFilterTypes}</styles.Message>}
          </>
        </styles.Wrapper>
      </styles.Container>
    )
  );
}

export default React.memo(Filters);

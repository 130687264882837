import React from "react";
import { useDispatch } from "react-redux";
import { setMarker, setLocation } from "../../../redux/actions";
import LocationList from "./LocationList";
import createLocationViewport from "../../../utils/createLocationViewport";

function Locations({ clusters, globalSelections, tile }) {
  const types = {};
  const dispatch = useDispatch();

  if (clusters) {
    clusters.forEach(cluster => {
      Object.keys(cluster.types).forEach(type => {
        const locations = cluster.types[type];
        if (globalSelections.includes(type)) {
          if (types[type]) {
            types[type].push(...locations);
          } else {
            types[type] = [...locations];
          }
        }
      });
    });
  }

  const handleLocationClick = (type, index) => {
    const location = types[type][index];
    dispatch(
      setLocation({
        location,
        viewport: createLocationViewport(location)
      })
    );
  };

  const handleLocationHoverOn = (type, index) => {
    const location = types[type][index];
    dispatch(setMarker(location.id));
  };

  const handleLocationHoverOut = () => {
    dispatch(setMarker(null));
  };

  return (
    types &&
    Object.entries(types).map(data => (
      <LocationList
        key={data[0]}
        type={data[0]}
        locations={data[1]}
        onLocationClick={index => handleLocationClick(data[0], index)}
        onLocationMouseEnter={index => handleLocationHoverOn(data[0], index)}
        onLocationMouseLeave={() => handleLocationHoverOut()}
      />
    ))
  );
}

export default React.memo(Locations);

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { HotelIcon, BarIcon, RestaurantIcon, DefaultIcon } from "../../utils/icons";
import * as styles from "./styles";
import { setTile } from "../../redux/actions";
import createRegionViewport from "../../utils/createRegionViewport";
import _ from "lodash";

function Types({ clusters, tile }) {
  const dispatch = useDispatch();
  const viewport = useSelector(state => state.viewport);
  const labels = useSelector(state => state.labels);

  if (_.isEmpty(clusters)) {
    return null;
  }

  const types =
    clusters &&
    clusters.reduce((obj, cluster) => {
      cluster &&
        cluster.types &&
        Object.entries(cluster.types).forEach(type => {
          if (obj[type[0]]) {
            obj[type[0]] = obj[type[0]] + type[1].length;
          } else {
            obj[type[0]] = type[1].length;
          }
        });
      return obj;
    }, {});

  const icons = {
    hotel: <HotelIcon />,
    nightclub: <BarIcon />,
    restaurant: <RestaurantIcon />
  };

  const handleTypeClick = type => {
    dispatch(
      setTile({
        tile: tile.id,
        filters: {
          types: [type]
        },
        viewport: createRegionViewport(tile, viewport)
      })
    );
  };

  return (
    types && (
      <styles.Types>
        {Object.keys(types).map(type => {
          const amount = types[type];
          const label = amount > 1 ? labels[type + "s"] : labels[type];
          const icon = icons[type] ? icons[type] : <DefaultIcon />;
          return (
            amount !== 0 && (
              <styles.Type key={type} data-type={type} onClick={() => handleTypeClick(type)}>
                <>
                  {icon}
                  {amount} {label ? label : type.charAt(0).toUpperCase() + type.slice(1)}
                </>
              </styles.Type>
            )
          );
        })}
      </styles.Types>
    )
  );
}

export default React.memo(Types);

import styled from "styled-components";

export const ButtonMap = styled.div`
    position: absolute;
    bottom: 6px;
    right: 5px;
    // border-radius: 43px;
    min-width: 48px;
    color: ${props => props.theme.colors.buttonTextColor};
    background: ${props => props.theme.colors.buttonColor};
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.02em;
    // text-transform: uppercase;
    display: inline-block;
    margin: 0 auto;
    cursor: pointer;
    white-space: nowrap;
    font-size: 14px;
    padding: 3px 9px;
    transition: all 0.5s ease;

    &:hover{
      transform: scale(1.1, 1.1);

      & > svg{
       margin-top: 5px;
       fill : #000;
       width: 27px;
      }

      color: ${props => props.theme.colors.buttonColor};
      background: ${props => props.theme.colors.buttonTextColor};
       
    }

    @media ${props => props.theme.devices.laptopS} {
      padding: 5px 20px;
      min-width: 162px;
      top: 10px;
      right: 15px;
      bottom: auto;
    }
  }

  & > svg{
    margin-top: 5px;
    fill : #FFF;
    width: 27px;
  }

`;

export const ButtonRegion = styled.div`
    position: absolute;
    top: 48px;
    right: 15px;
    // border-radius: 43px;
    min-width: 162px;
    color: ${props => props.theme.colors.buttonTextColor};
    background: ${props => props.theme.colors.buttonColor};
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.02em;
    // text-transform: uppercase;
    display: inline-block;
    margin: 0 auto;
    cursor: pointer;
    white-space: nowrap;
    font-size: 14px;
    padding: 3px 17px;
    transition: all 0.5s ease;
    display: none;

    @media ${props => props.theme.devices.laptopS}{
      display: block;
    }

    &:hover{
      transform: scale(1.1, 1.1);

      color: ${props => props.theme.colors.buttonColor};
      background: ${props => props.theme.colors.buttonTextColor};
       
    }

    @media ${props => props.theme.devices.tablet} {
      padding: 5px 20px;
    }
  }


`;

export const ButtonLocations = styled.div`
    position: absolute;
    top: 86px;
    right: 15px;
    // border-radius: 43px;
    min-width: 162px;
    color: ${props => props.theme.colors.buttonTextColor};
    background: ${props => props.theme.colors.buttonColor};
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.02em;
    // text-transform: uppercase;
    display: inline-block;
    margin: 0 auto;
    cursor: pointer;
    white-space: nowrap;
    font-size: 14px;
    padding: 3px 17px;
    transition: all 0.5s ease;
    display: none;

    @media ${props => props.theme.devices.laptopS}{
      display: block;
    }

    &:hover{
      transform: scale(1.1, 1.1);

      color: ${props => props.theme.colors.buttonColor};
      background: ${props => props.theme.colors.buttonTextColor};
       
    }

    @media ${props => props.theme.devices.tablet} {
      padding: 5px 20px;
    }
  }


`;
export const Container = styled.div``;

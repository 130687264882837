import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as styles from "./styles";
import { setLocation, setMarker } from "../../redux/actions";
import Type from "./Type";
import filterTiles from "../../utils/filterTiles";
import getLocationTypeCounts from "../../utils/getLocationTypeCounts";
import getClusters from "../../utils/getClusters";
import getTypes from "../../utils/getTypes";
import getTile from "../../utils/getTile";
import viewState from "../../config/viewState";
import createLocationViewport from "../../utils/createLocationViewport";
import getLocationsByType from "../../utils/getLocationsByType";
import { size } from "../../themes/index.variables";

function Results() {
  const tiles = useSelector(state => state.tiles);
  const labels = useSelector(state => state.labels);
  const filters = useSelector(state => state.filters);
  const step = useSelector(state => state.step);
  const filteredTiles = filterTiles(tiles, filters);

  const dispatch = useDispatch();
  const [selectedType, setSelectedType] = useState(false);
  const filteredTypes = getLocationsByType(getClusters(filteredTiles));
  const locationTypeCounts = getLocationTypeCounts(filteredTypes);

  const handleLocationClick = location => {
    if (location) {
      const tile = getTile(tiles, location.tileId);
      dispatch(
        setLocation({
          location,
          clusterId: location.clusterId,
          filters: {
            term: "",
            brand: "",
            types: getTypes(getClusters(tile)),
            searchFilter: ""
          },
          viewport: createLocationViewport(location)
        })
      );
    }
  };

  const handleLocationHoverOn = location => {
    if (step === viewState.search) {
      dispatch(setMarker(location.clusterId));
    } else {
      dispatch(setMarker(location.id));
    }
  };

  const handleLocationHoverOut = () => {
    dispatch(setMarker(null));
  };

  const handleTabClick = type => {
    if (locationTypeCounts[type] > 0) {
      setSelectedType(type);
    }
  };

  useEffect(() => {
    if (window.innerWidth < size.laptopS && locationTypeCounts.total > 0) {
      if (!selectedType || locationTypeCounts[selectedType] === 0) {
        let type = false;

        /**
         * Get first type with count > 0
         */
        Object.keys(filteredTypes).forEach(function(locationType) {
          if (filteredTypes[locationType].length > 0 && !type) {
            type = locationType;
          }
        });
        setSelectedType(type);
      }
    }
  }, [selectedType, filteredTypes, locationTypeCounts]);

  return (
    <styles.Results>
      {locationTypeCounts.total > 0 ? (
        <>
          <styles.Total>
            {locationTypeCounts.total}{" "}
            {locationTypeCounts.total > 1 ? labels.locationsFound : labels.locationFound}
          </styles.Total>
          {filteredTypes &&
            Object.keys(filteredTypes).map((type, i) => {
              const locations = filteredTypes[type];
              const amount = locations.length;
              const label = amount > 1 ? labels[type + "s"] : labels[type];

              if (selectedType && selectedType !== type) {
                return null;
              }

              return (
                <styles.Type key={`${type}${i}`}>
                  <h1>
                    {amount} {label ? label : type.charAt(0).toUpperCase() + type.slice(1)}
                  </h1>
                  {
                    <Type
                      type={locations}
                      onLocationClick={handleLocationClick}
                      onLocationMouseEnter={handleLocationHoverOn}
                      onLocationMouseLeave={handleLocationHoverOut}
                    />
                  }
                </styles.Type>
              );
            })}
        </>
      ) : (
        filters.term && (
          <p>
            {labels.noSearchResults} "{`${filters.term}`}"
          </p>
        )
      )}
      {window.innerWidth < size.laptopS && (
        <styles.Tabs>
          {filteredTypes &&
            Object.keys(filteredTypes).map((type, i) => {
              const locations = filteredTypes[type];
              const amount = locations.length;
              return (
                <styles.Tab
                  key={type}
                  className={selectedType === type ? "active" : ""}
                  onClick={() => handleTabClick(type)}
                >
                  <h1>{amount}</h1>
                  <label>{labels[type]}</label>
                </styles.Tab>
              );
            })}
        </styles.Tabs>
      )}
    </styles.Results>
  );
}
export default Results;

import _ from "lodash";
import filterTile from "./filterTile";

/**
 */
export default function filterTiles(tiles, filters) {
  const results = [];
  if (!_.isEmpty(tiles)) {
    tiles.forEach(tile => {
      results.push(filterTile(tile, filters));
    });
  }
  return results;
}

export const SET_HIGHLIGHT = "SET_HIGHLIGHT";
export const SET_IS_TRANSITIONING = "SET_IS_TRANSITIONING";
export const SET_CLUSTER = "SET_CLUSTER";
export const SET_TILE = "SET_TILE";
export const SET_STEP = "SET_STEP";
export const SET_TILES = "SET_TILES";
export const SET_MARKER = "SET_MARKER";
export const SET_VIEWPORT = "SET_VIEWPORT";
export const SET_SEARCH = "SET_SEARCH";
export const SET_MAP_DATA = "SET_MAP_DATA";
export const RESET_MAP = "RESET_MAP";
export const SET_LOCATION = "SET_LOCATION";
export const FILTER_TO_REGION = "FILTER_TO_REGION";
export const FILTER_TO_LOCATIONS = "FILTER_TO_LOCATIONS";
export const SET_DEFAULT_VIEWSTATE = "SET_DEFAULT_VIEWSTATE";
export const SET_FILTERS = "SET_FILTERS";
export const SET_LABELS = "SET_LABELS";

export const setMapData = payload => {
  return {
    type: SET_MAP_DATA,
    payload
  };
};

export const setSearch = payload => {
  return {
    type: SET_SEARCH,
    payload
  };
};

export const setHighlight = payload => {
  return {
    type: SET_HIGHLIGHT,
    payload
  };
};

export const setViewport = payload => {
  return {
    type: SET_VIEWPORT,
    payload
  };
};

export const setTiles = payload => {
  return {
    type: SET_TILES,
    payload
  };
};

export const setStep = payload => {
  return {
    type: SET_STEP,
    payload
  };
};

export const setTile = payload => {
  return {
    type: SET_TILE,
    payload
  };
};

export const setIsTransitioning = payload => {
  return {
    type: SET_IS_TRANSITIONING,
    payload
  };
};

export const setCluster = payload => {
  return {
    type: SET_CLUSTER,
    payload
  };
};

export const resetMap = payload => {
  return {
    type: RESET_MAP,
    payload
  };
};

export const setLocation = payload => {
  return {
    type: SET_LOCATION,
    payload
  };
};

export const filterToRegion = payload => {
  return {
    type: FILTER_TO_REGION,
    payload
  };
};

export const filterToLocations = payload => {
  return {
    type: FILTER_TO_LOCATIONS,
    payload
  };
};

export const setDefaultViewState = payload => {
  return {
    type: SET_DEFAULT_VIEWSTATE,
    payload
  };
};

export const setFilters = payload => {
  return {
    type: SET_FILTERS,
    payload
  };
};

export const setMarker = payload => {
  return {
    type: SET_MARKER,
    payload
  };
};

export const setLabels = payload => {
  return {
    type: SET_LABELS,
    payload
  };
};

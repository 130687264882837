import countries from "./geoData/countriesGeoJson.json";
import europe from "./geoData/europeGeoJson.json";
import northAmerica from "./geoData/northAmericaGeoJson.json";
import southAmerica from "./geoData/southAmericaGeoJson.json";
import africa from "./geoData/africaGeoJson.json";
import asia from "./geoData/asiaGeoJson.json";
import oceania from "./geoData/oceaniaGeoJson.json";

export default function hightlightMap(regions) {
  const found =
    regions &&
    regions.length &&
    regions
      .map(country => {
        const countryFound = countries.features.find(
          feature => feature.id.toLowerCase() === country.toLowerCase()
        );

        if (countryFound) {
          return countryFound;
        }

        switch (country.toLowerCase()) {
          case "europe":
            return europe;
          case "north america":
          case "northamerica":
            return northAmerica;
          case "south america":
          case "southamerica":
            return southAmerica;
          case "africa":
            return africa;
          case "oceania":
            return oceania;
          case "asia":
            return asia;
          default:
            return null;
        }
      })
      .filter(regions => regions);

  return (
    found.length > 0 && {
      type: "FeatureCollection",
      features: [...found]
    }
  );
}

import _ from "lodash";

/**
 * Get a cluster by id from a given tile
 */
export default function getTile(tile, id) {
  if (tile && id) {
    const clusters = _.get(tile, "clusters", []);
    return _.find(clusters, { id });
  }
}

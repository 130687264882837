import styled, { keyframes } from "styled-components";

const CircAnim = keyframes`
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }`;

export const Spinner = styled.div`
  margin: 1em;
  display: inline-block;
  width: 6em;
  height: 6em;
  border-radius: 50%;
  border: 4px solid silver;
  animation: ${CircAnim} 0.7s linear infinite;
  border: 3px solid grey;
  position: relative;
  border-bottom-color: transparent;
  border-left-color: transparent;

  &:after {
    position: absolute;
    content: "";
    width: 80%;
    height: 80%;
    background: transparent;
    top: 50%;
    left: 50%;
    margin-left: -40%;
    margin-top: -40%;
    border-radius: 50%;
    border: 2px solid grey;
    box-sizing: border-box;
    border-bottom-color: transparent;
    border-left-color: transparent;
    animation: ${CircAnim} 0.35s reverse linear infinite;
  }
`;

export const Wrapper = styled.div`
  text-align: center;
  width: 100%;
  max-width: 1920px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 11;
  background: #e8e8e8;
  transition: all 1s ease;
  opacity: 0;
  top: 0;
  bottom: :0;
  bottom: 0;

  
  &.show {
    opacity: 1;
  }

  img{
    max-width: 130px;
  }
`;

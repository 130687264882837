export default {
  restaurant: "Restaurant",
  restaurants: "Restaurants",
  hotel: "Hotel",
  hotels: "Hotels",
  nightlife: "Nightlife",
  nightlifeVenues: "Nightlife Venues",
  locations: "Locations",
  filters: "Filters",
  name: "Name",
  brand: "Brand",
  showing: "Showing",
  of: "of",
  directions: "Directions",

  filterBy: "Filter by",
  filterByType: "Filter by Type",
  filterByTypes: "Filter by Types",
  seeAllLocations: "See All Locations",
  seeAllClusters: "See All Clusters",
  backToMap: "Back to Map",
  backToRegion: "Back to Region",
  backToLocations: "Back to Locations",
  searchPlaceholder: "Enter the location's name",
  locationFound: "Location Found",
  locationsFound: "Locations Found",
  showMore: "Show More",
  noSearchResults: "No results found for",
  noFilterTypes: "Select filters to see locations",
  viewHotel: "View Hotel",
  viewNightlife: "View Nightlife",
  viewRestaurant: "View Restaurant",
  loading: "Loading..."
};

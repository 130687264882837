import React from "react";
import { useSelector } from "react-redux";
import { size } from "../../themes/index.variables";
import * as styles from "./styles";
import _ from "lodash";

import {
  RestaurantIcon,
  BarIcon,
  HotelIcon,
  DefaultIcon,
  PhoneIcon,
  AddressIcon,
  SiteIcon,
  GoogleMapIcon
} from "../../utils/icons";

export default function InfoWindow(props) {
  const { type, name, phone, address, image, link, description } = props;
  const labels = useSelector(state => state.labels);
  const images = useSelector(state => state.config.images);
  const icons = {
    hotel: <HotelIcon />,
    nightclub: <BarIcon />,
    restaurant: <RestaurantIcon />
  };
  const addressQuery =
    _.get(address, "addressLine[0]", "") + " " + _.get(address, "city", "");
  const searchQuery = encodeURIComponent(`${name} ${addressQuery}`);
  const viewLabelKey = "view" + type.charAt(0).toUpperCase() + type.slice(1);
  const viewLabel = labels[viewLabelKey];

  const renderDescription = description => {
    const showMore = e => {
      e.target.parentElement.innerHTML = description;
    };
    if (!description) return null;
    const smallText = description
      .split(" ")
      .slice(0, 15)
      .join(" ");

    if (
      window.innerWidth < size.laptopM &&
      description.split(" ").length > 15
    ) {
      return (
        <styles.Description>
          {smallText}... <span onClick={showMore}>read more!</span>
        </styles.Description>
      );
    } else {
      return <styles.Description>{description}</styles.Description>;
    }
  };

  return (
    <styles.Wrapper>
      <styles.Image>
        <img
          src={`${image ? image : images[type + "Placeholder"]}`}
          alt="a featured property"
        />
        <styles.Text>
          {type && (
            <span>
              {icons[type] ? icons[type] : <DefaultIcon />}
              {labels[type]}
            </span>
          )}
          <h1>{name}</h1>
          {description && renderDescription(description)}
          {address && (
            <styles.Address>
              <AddressIcon />
              <div>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://www.google.com/maps/search/?q=${searchQuery}`}
                >
                  {address.addressLine &&
                    address.addressLine.map((line, i) => <p key={i}>{line}</p>)}
                  {address.street && <p>{address.street}</p>}
                  <p>
                    {address.cityName}{" "}
                    {address.stateProv && `- ${address.stateProv}`}{" "}
                    {address.postalCode && `- ${address.postalCode}`}
                  </p>
                </a>
              </div>
            </styles.Address>
          )}
          {phone && (
            <styles.Phone>
              <PhoneIcon />
              <a href={"tel:" + phone}>{phone}</a>
            </styles.Phone>
          )}
        </styles.Text>
        <styles.Navigation>
          {link && (
            <a href={link}>
              {window.innerWidth > size.laptopS ? viewLabel : <SiteIcon />}
            </a>
          )}
          {address && address.addressLine && address.stateProv && (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://www.google.com/maps/search/?q=${searchQuery}`}
            >
              {window.innerWidth > size.laptopS ? (
                labels.directions
              ) : (
                <GoogleMapIcon />
              )}
            </a>
          )}
        </styles.Navigation>
      </styles.Image>
    </styles.Wrapper>
  );
}

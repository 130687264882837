import React, { useState } from "react";
import { useSelector } from "react-redux";
import LocationCard from "./../Filters/Locations/LocationCard";
import * as styles from "./styles";
import { size } from "../../themes/index.variables";

function Type({ type, onLocationClick, onLocationMouseEnter, onLocationMouseLeave }) {
  const showAmount = window.innerWidth < size.laptopS ? type.length : 3;

  const images = useSelector(state => state.config.images);

  const [showMore, setShowMore] = useState(showAmount);

  const handleShowMore = () => {
    setShowMore(showMore + showAmount);
  };

  return (
    <styles.Types>
      {type
        .sort(function(a, b) {
          return ("" + a.name).localeCompare(b.name);
        })
        .slice(0, showMore)
        .map((location, i) => {
          return (
            <LocationCard
              key={i}
              name={location.name}
              address={location.address}
              image={location.image}
              phone={location.phone}
              onClick={() => onLocationClick(location, i)}
              onMouseEnter={() => onLocationMouseEnter(location, i)}
              onMouseLeave={() => onLocationMouseLeave(location, i)}
              imagePlaceholder={images[location.type + "Placeholder"]}
            />
          );
        })}
      {showMore < type.length && (
        <p>{`Showing ${showMore > type.length ? type.length : showMore} of ${type.length}`}</p>
      )}
      {showMore < type.length && <p onClick={handleShowMore}>Show More</p>}
    </styles.Types>
  );
}

export default Type;

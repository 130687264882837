import React from "react";
import { MarkerIcon } from "../../utils/icons";
import * as styles from "./styles";
import _ from "lodash";
const Cluster = ({ cluster, tile, position, onClusterClick, total }) => {
  if (
    !Boolean(cluster) ||
    !total ||
    !Boolean(_.get(position, "x")) ||
    !Boolean(_.get(position, "y"))
  ) {
    return null;
  }

  return (
    <styles.Container position={position} onClick={() => onClusterClick(cluster)}>
      {cluster.clusterIcon ? <img src={cluster.clusterIcon} alt="cluster icon" /> : <MarkerIcon />}
      {total && <styles.Count>{total}</styles.Count>}

      {cluster.name && (
        <styles.Info>
          <p>{cluster.name}</p>
        </styles.Info>
      )}
    </styles.Container>
  );
};

export default Cluster;

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as styles from "./styles";
import { resetMap, setTile, filterToLocations } from "../../redux/actions";

import { size } from "../../themes/index.variables";
import { WorldIcon } from "../../utils/icons";
import viewState from "../../config/viewState";
import getTile from "../../utils/getTile";
import getCluster from "../../utils/getCluster";
import getClusters from "../../utils/getClusters";
import getTypes from "../../utils/getTypes";
import createBaseViewport from "../../utils/createBaseViewport";
import createRegionViewport from "../../utils/createRegionViewport";
import createSearchViewport from "../../utils/createSearchViewport";
import createClusterViewport from "../../utils/createClusterViewport";

const Navigation = () => {
  const viewport = useSelector(state => state.viewport);
  const step = useSelector(state => state.step);
  const tiles = useSelector(state => state.tiles);
  const selectedTile = getTile(
    tiles,
    useSelector(state => state.selectedTile)
  );
  const labels = useSelector(state => state.labels);
  const selectedCluster = getCluster(
    selectedTile,
    useSelector(state => state.selectedCluster)
  );
  const clusters = selectedCluster ? [selectedCluster] : getClusters(selectedTile);

  const dispatch = useDispatch();

  const handleResetMap = () => {
    dispatch(resetMap(createBaseViewport()));
  };

  const backToRegion = () => {
    dispatch(
      setTile({
        tile: selectedTile.id,
        viewport: createRegionViewport(selectedTile, viewport),
        filters: {
          term: "",
          searchFilter: "",
          brand: "",
          types: getTypes(getClusters(selectedTile))
        }
      })
    );
  };

  const backToLocations = () => {
    if (clusters.length === 1) {
      dispatch(
        filterToLocations({
          location: null,
          viewport: {
            ...createClusterViewport(clusters[0], viewport)
          }
        })
      );
    } else {
      dispatch(
        filterToLocations({
          location: null,
          viewport: {
            ...createSearchViewport(clusters, viewport)
          }
        })
      );
    }
  };

  return (
    (step === viewState.clusters || step === viewState.locations) && (
      <styles.Container>
        <styles.ButtonMap className="btn" onClick={handleResetMap}>
          {window.innerWidth < size.laptopS ? <WorldIcon /> : labels.backToMap}
        </styles.ButtonMap>
        {step === viewState.locations && (
          <styles.ButtonRegion className="btn" onClick={backToRegion}>
            {labels.backToRegion}
          </styles.ButtonRegion>
        )}
        {step === viewState.locations && (
          <styles.ButtonLocations className="btn" onClick={backToLocations}>
            {labels.backToLocations}
          </styles.ButtonLocations>
        )}
      </styles.Container>
    )
  );
};

export default React.memo(Navigation);

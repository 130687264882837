import { defaultMapStyle } from "../utils/mapStyles";
import labels from "../config/labels.js";
import images from "../config/images.js";

const localConfig = localStorage.getItem("queryGeoTilesConfig")
  ? JSON.parse(localStorage.getItem("queryGeoTilesConfig"))
  : {};

export default {
  mapData: {
    latitude: 20,
    longitude: 0,
    width: "100%",
    height: "100%",
    zoom: 1.5
  },
  config: {
    language: "en",
    hasNavigation: true,
    hasFilter: true,
    hasBackButtons: true,
    hasCountriesHover: true,
    hasTiles: true,
    hasTypeList: true,
    hasCircles: true,
    hasLines: true,
    images,
    ...localConfig
  },
  tiles: [],
  filters: {
    term: "",
    types: [],
    brand: "",
    searchFilter: ""
  },
  viewport: defaultMapStyle,
  selectedTile: null,
  selectedCluster: null,
  selectedLocation: null,
  selectedMarker: null,
  step: 1,
  defaultViewState: 1,
  highlight: null,
  isTransitioning: false,
  labels
};
